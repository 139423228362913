var g_imgUtvonal = 'img/';

var g_joKarakterekMezo = '[a-zA-ZáÁéÉíÍúÚüÜűŰóÓöÖőŐ]';

var g_fejtesID = 0;

var g_rejtvenyID = 0;

var g_rejtveny = {};

var g_morejtveny = {};

var g_beallitas = {};

var g_epitoMod = true;

var g_elozmeny = {};

var g_aktualisObj = {};
var g_aktualisMezoID = 0;

var g_aktualisTomb = [];
var g_aktualisInvTomb = [];

var g_mezoIrany = true;

var g_meghatarozasMenu = {};
var g_foMenu = {};
var g_megoldasMenu = {};

var g_mezoKiemeles = false;

var g_meghatarozasJeloles = false;
var g_szinkronSzerkesztes = false;

var g_ismertJeloles = false;

var g_maszkJeloles = false;

var g_ellenorizve = false;

var g_mentve = true;

var g_keretszin = '';
var g_base64Szines = '';

var g_base64Hid = '';

function rendezes(a, b) {
    return a - b;
}

function Csomag(csomagID, megnevezes) {
    this.csomagID = csomagID;

    this.elozoID = null;
    this.kovetkezoID = null;

    this.megnevezes = megnevezes;

    this.rejtvenyXML = g_rejtveny.toXML();

    this.getID = function () {
        return this.csomagID;
    };

    this.setElozoID = function (ertek) {
        this.elozoID = ertek;
    };
    this.getElozoID = function () {
        return this.elozoID;
    };
    this.setKovetkezoID = function (ertek) {
        this.kovetkezoID = ertek;
    };
    this.getKovetkezoID = function () {
        return this.kovetkezoID;
    };

    this.getMegnevezes = function () {
        return this.megnevezes;
    };

    this.toString = function () {
        return this.elozoID + ':' + this.csomagID + ':' + this.kovetkezoID;
    };

    this.reloadRejtveny = function () {
        reloadRejtveny(this.rejtvenyXML, g_aktualisMezoID, g_mezoIrany);
    };
}

function Elozmeny(maxMentes) {
    this.elozmenyTomb = [];

    this.aktualisCsomag = {};

    this.sorszam = -1;

    this.mentes = function (megnevezes) {
        this.sorszam++;

        var csomag = new Csomag(this.sorszam, megnevezes);

        if (this.sorszam > 0) {
            this.clearElozmeny(this.aktualisCsomag.getID());

            this.getCsomag(this.aktualisCsomag.getID()).setKovetkezoID(
                this.sorszam,
            );
            csomag.setElozoID(this.aktualisCsomag.getID());

            document.getElementById('zarRejtveny').style.display = 'block';
            document.getElementById('zarFixMenu').style.display = 'block';

            g_mentve = false;
            g_ellenorizve = false;
        }

        this.aktualisCsomag = csomag;

        this.elozmenyTomb.push(csomag);

        if (this.elozmenyTomb.length > maxMentes) {
            this.elozmenyTomb.shift();
            this.elozmenyTomb[0].setElozoID(null);
        }
    };

    this.ugras = function (csomagID) {
        this.aktualisCsomag = this.getCsomag(csomagID);

        this.aktualisCsomag.reloadRejtveny();
    };

    this.visszavonas = function () {
        this.aktualisCsomag = this.getCsomag(this.aktualisCsomag.getElozoID());

        this.aktualisCsomag.reloadRejtveny();
    };

    this.ismet = function () {
        this.aktualisCsomag = this.getCsomag(
            this.aktualisCsomag.getKovetkezoID(),
        );

        this.aktualisCsomag.reloadRejtveny();
    };

    this.hasVisszavonasGomb = function () {
        return this.aktualisCsomag.getElozoID() != null;
    };

    this.getVisszavonasFelirat = function () {
        return (
            'Visszavonás: ' + this.aktualisCsomag.getMegnevezes() + ' (Ctrl+Z)'
        );
    };

    this.hasIsmetGomb = function () {
        return this.aktualisCsomag.getKovetkezoID() != null;
    };

    this.getIsmetFelirat = function () {
        var csomag = this.getCsomag(this.aktualisCsomag.getKovetkezoID());

        return 'Ismét: ' + csomag.getMegnevezes() + ' (Ctrl+Y)';
    };

    this.getCsomag = function (csomagID) {
        var i;

        for (i = 0; i < this.elozmenyTomb.length; i++) {
            if (this.elozmenyTomb[i].getID() === csomagID) {
                return this.elozmenyTomb[i];
            }
        }

        return null;
    };

    this.clearElozmeny = function (csomagID) {
        var i = 0;

        while (i < this.elozmenyTomb.length) {
            if (this.elozmenyTomb[i].getID() > csomagID) {
                this.elozmenyTomb.splice(i);
            }

            i++;
        }
    };

    this.toString = function () {
        var str = '<span class="cim">Előzmények (' + maxMentes + ')</span>';
        var i;

        for (i = 0; i < this.elozmenyTomb.length; i++) {
            str +=
                '<a href="javascript:elozmenyUgras(' +
                this.elozmenyTomb[i].getID() +
                ')">';

            if (this.elozmenyTomb[i].getID() === this.aktualisCsomag.getID()) {
                str += '<b>' + this.elozmenyTomb[i].getMegnevezes() + '</b>';
            } else {
                str += this.elozmenyTomb[i].getMegnevezes();
            }

            str += '</a>';
        }
        return str;
    };
}

function Mezo(
    ID,
    ertek,
    tipus,
    vonal,
    gyengevonal,
    vMeghatarozas,
    fMeghatarozas,
    vhMeghatarozas,
    fhMeghatarozas,
    vKifejezes,
    fKifejezes,
    vhKifejezes,
    fhKifejezes,
    hidpozicio,
    ismert,
) {
    this.ertek = null;
    this.tipus = null;

    this.vvonal = false;
    this.fvonal = false;

    this.vgyengevonal = false;
    this.fgyengevonal = false;

    this.engedelyezvvonal = true;
    this.engedelyezfvonal = true;

    this.vMeghatarozas = null;
    this.fMeghatarozas = null;
    this.vhMeghatarozas = null;
    this.fhMeghatarozas = null;

    this.fekete = false;
    this.abra = false;
    this.ures = false;

    this.ismert = null;

    this.mezoID = 'mezo-' + ID;

    this.ID = ID;

    this.stilus = '';

    this.vKifejezesHiba = false;
    this.fKifejezesHiba = false;
    this.vhKifejezesHiba = false;
    this.fhKifejezesHiba = false;

    this.vKifejezes = null;
    this.fKifejezes = null;
    this.vhKifejezes = null;
    this.fhKifejezes = null;

    this.hidpozicio = null;

    this.getID = function () {
        return this.ID;
    };
    this.getMezoID = function () {
        return this.mezoID;
    };

    this.getErtek = function () {
        return this.ertek;
    };
    this.getTipus = function () {
        return this.tipus;
    };
    this.isVvonal = function () {
        return this.vvonal;
    };
    this.isFvonal = function () {
        return this.fvonal;
    };

    this.isVgyengevonal = function () {
        return this.vgyengevonal;
    };
    this.isFgyengevonal = function () {
        return this.fgyengevonal;
    };

    this.isFekete = function () {
        return this.fekete;
    };
    this.isAbra = function () {
        return this.abra;
    };
    this.isUres = function () {
        return this.ures;
    };
    this.isSzines = function () {
        return this.tipus === 'színes';
    };
    this.isHid = function () {
        return this.tipus === 'híd';
    };

    this.isIsmert = function () {
        return this.ismert == 1;
    };

    this.setIsmert = function (ismert) {
        var tmp = this.ismert;

        if (ismert == 1) {
            this.ismert = true;
        } else if (this.ismert == null) {
            this.ismert = false;
        } else {
            this.ismert = !tmp;
        }
    };
    this.setIsmert(ismert);

    this.setErtek = function (ertek) {
        this.ertek = ertek;
        switch (ertek) {
            case '#':
                this.fekete = true;
                break;
            case '@':
                this.abra = true;
                break;
            case '-':
                this.ures = true;
                break;
        }
    };
    this.setErtek(ertek);

    this.setTipus = function (tipus) {
        if (tipus === '') {
            tipus = null;
        }
        this.tipus = tipus;
    };
    this.setTipus(tipus);

    this.setVvonal = function () {
        this.vvonal = !this.vvonal;
    };
    this.setFvonal = function () {
        this.fvonal = !this.fvonal;
    };
    this.setFekete = function () {
        var tmp = this.fekete;

        this.setUresMezo();
        if (!tmp) {
            this.ertek = '#';
        }
        this.fekete = !tmp;
    };
    this.setAbra = function () {
        var tmp = this.abra;

        this.setUresMezo();
        if (!tmp) {
            this.ertek = '@';
        }
        this.abra = !tmp;
    };
    this.setUres = function () {
        var tmp = this.ures;

        this.setUresMezo();
        if (!tmp) {
            this.ertek = '-';
        }
        this.ures = !tmp;
    };

    this.setSzines = function () {
        if (this.tipus === 'színes') {
            this.setTipus('');
        } else {
            this.setTipus('színes');
        }
    };
    this.setHid = function () {
        if (this.tipus === 'híd') {
            this.setTipus('');
        } else {
            this.setTipus('híd');
        }
    };

    this.setVonal = function (vonal) {
        switch (vonal) {
            case 'v':
                this.vvonal = true;
                break;
            case 'f':
                this.fvonal = true;
                break;
            case 'vf':
                this.fvonal = true;
                this.vvonal = true;
                break;
        }
    };
    this.setVonal(vonal);

    this.setGyengevonal = function (gyengevonal) {
        switch (gyengevonal) {
            case 'v':
                this.vgyengevonal = true;
                break;
            case 'f':
                this.fgyengevonal = true;
                break;
            case 'vf':
                this.fgyengevonal = true;
                this.vgyengevonal = true;
                break;
        }
    };
    this.setGyengevonal(gyengevonal);

    this.setVmeghatarozas = function (v) {
        if (v === '') {
            v = null;
        }
        this.vMeghatarozas = v;
    };
    this.setVmeghatarozas(vMeghatarozas);

    this.getVmeghatarozas = function () {
        return this.vMeghatarozas;
    };
    this.hasVmeghatarozas = function () {
        return this.vMeghatarozas !== null;
    };

    this.setFmeghatarozas = function (f) {
        if (f === '') {
            f = null;
        }
        this.fMeghatarozas = f;
    };
    this.setFmeghatarozas(fMeghatarozas);

    this.getFmeghatarozas = function () {
        return this.fMeghatarozas;
    };
    this.hasFmeghatarozas = function () {
        return this.fMeghatarozas !== null;
    };

    this.setVHmeghatarozas = function (vh) {
        if (vh === '') {
            vh = null;
        }
        this.vhMeghatarozas = vh;
    };
    this.setVHmeghatarozas(vhMeghatarozas);

    this.getVHmeghatarozas = function () {
        return this.vhMeghatarozas;
    };
    this.hasVHmeghatarozas = function () {
        return this.vhMeghatarozas !== null;
    };

    this.setFHmeghatarozas = function (fh) {
        if (fh === '') {
            fh = null;
        }
        this.fhMeghatarozas = fh;
    };
    this.setFHmeghatarozas(fhMeghatarozas);

    this.getFHmeghatarozas = function () {
        return this.fhMeghatarozas;
    };
    this.hasFHmeghatarozas = function () {
        return this.fhMeghatarozas !== null;
    };

    this.setHidPozicio = function (ertek) {
        if (ertek === '') {
            ertek = 0;
        }
        this.hidpozicio = ertek;
    };
    this.setHidPozicio(hidpozicio);

    this.getHidPozicio = function () {
        return this.hidpozicio;
    };

    this.engedelyezVVonal = function () {
        return this.engedelyezvvonal;
    };

    this.letiltVVonal = function () {
        this.engedelyezvvonal = false;
    };

    this.engedelyezFVonal = function () {
        return this.engedelyezfvonal;
    };

    this.letiltFVonal = function () {
        this.engedelyezfvonal = false;
    };

    this.setUresMezo = function () {
        this.ertek = null;
        this.tipus = null;

        this.vMeghatarozas = null;
        this.fMeghatarozas = null;
        this.vhMeghatarozas = null;
        this.fhMeghatarozas = null;

        this.vvonal = false;
        this.fvonal = false;

        this.fekete = false;
        this.abra = false;
        this.ures = false;

        this.vKifejezesHiba = false;
        this.fKifejezesHiba = false;
        this.vhKifejezesHiba = false;
        this.fhKifejezesHiba = false;

        this.vKifejezes = null;
        this.fKifejezes = null;
        this.vhKifejezes = null;
        this.fhKifejezes = null;

        this.hidpozicio = null;
    };

    this.setUresErtek = function () {
        this.ertek = null;
        this.tipus = null;

        this.fekete = false;
        this.abra = false;
        this.ures = false;
    };

    this.enableVkifejezesHiba = function () {
        this.vKifejezesHiba = true;
    };
    this.disableVkifejezesHiba = function () {
        this.vKifejezesHiba = false;
    };
    this.hasVkifejezesHiba = function () {
        return this.vKifejezesHiba;
    };
    this.setVkifejezes = function (v) {
        if (v === '') {
            v = null;
        }
        this.vKifejezes = v;
    };
    this.setVkifejezes(vKifejezes);

    this.getVkifejezes = function () {
        return this.vKifejezes;
    };

    this.enableFkifejezesHiba = function () {
        this.fKifejezesHiba = true;
    };
    this.disableFkifejezesHiba = function () {
        this.fKifejezesHiba = false;
    };
    this.hasFkifejezesHiba = function () {
        return this.fKifejezesHiba;
    };
    this.setFkifejezes = function (kifejezes) {
        if (kifejezes === '') {
            kifejezes = null;
        }
        this.fKifejezes = kifejezes;
    };
    this.setFkifejezes(fKifejezes);

    this.getFkifejezes = function () {
        return this.fKifejezes;
    };

    this.enableVHkifejezesHiba = function () {
        this.vhKifejezesHiba = true;
    };
    this.disableVHkifejezesHiba = function () {
        this.vhKifejezesHiba = false;
    };
    this.hasVHkifejezesHiba = function () {
        return this.vhKifejezesHiba;
    };
    this.setVHkifejezes = function (ertek) {
        if (ertek === '') {
            ertek = null;
        }
        this.vhKifejezes = ertek;
    };
    this.setVHkifejezes(vhKifejezes);

    this.getVHkifejezes = function () {
        return this.vhKifejezes;
    };

    this.enableFHkifejezesHiba = function () {
        this.fhKifejezesHiba = true;
    };
    this.disableFHkifejezesHiba = function () {
        this.fhKifejezesHiba = false;
    };
    this.hasFHkifejezesHiba = function () {
        return this.fhKifejezesHiba;
    };
    this.setFHkifejezes = function (ertek) {
        if (ertek === '') {
            ertek = null;
        }
        this.fhKifejezes = ertek;
    };
    this.setFHkifejezes(fhKifejezes);

    this.getFHkifejezes = function () {
        return this.fhKifejezes;
    };

    this.hasKifejezesHiba = function () {
        return (
            this.hasVkifejezesHiba() ||
            this.hasFkifejezesHiba() ||
            this.hasVHkifejezesHiba() ||
            this.hasFHkifejezesHiba()
        );
    };

    this.setSorTores = function () {
        this.stilus += 'clear:both; float:left; ';
    };
    this.setBorderLeft = function () {
        this.stilus += 'border-left:1px solid rgb(213, 213, 213); ';
    };
    this.setBorderTop = function () {
        this.stilus += 'border-top:1px solid rgb(213, 213, 213); ';
    };

    this.getBackgroundImage = function (vizszintes, onlywarning) {
        var backgroundimage = '';

        onlywarning = onlywarning == undefined ? false : true;

        var tmp;

        if (
            vizszintes == undefined ||
            vizszintes ||
            this.hasVkifejezesHiba() ||
            this.hasVHkifejezesHiba()
        ) {
            if (this.hasVmeghatarozas()) {
                if (this.hasVkifejezesHiba()) {
                    tmp = g_imgUtvonal + 'vmegh_warning.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
                if (!onlywarning) {
                    tmp = g_imgUtvonal + 'vmegh.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
            }

            if (this.hasVHmeghatarozas()) {
                if (this.hasVHkifejezesHiba()) {
                    tmp = g_imgUtvonal + 'vhmegh_warning.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
                if (!onlywarning) {
                    tmp = g_imgUtvonal + 'vhmegh.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
            }
        }
        if (
            vizszintes == undefined ||
            !vizszintes ||
            this.hasFkifejezesHiba() ||
            this.hasFHkifejezesHiba()
        ) {
            if (this.hasFmeghatarozas()) {
                if (this.hasFkifejezesHiba()) {
                    tmp = g_imgUtvonal + 'fmegh_warning.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
                if (!onlywarning) {
                    tmp = g_imgUtvonal + 'fmegh.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
            }

            if (this.hasFHmeghatarozas()) {
                if (this.hasFHkifejezesHiba()) {
                    tmp = g_imgUtvonal + 'fhmegh_warning.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
                if (!onlywarning) {
                    tmp = g_imgUtvonal + 'fhmegh.png';
                    backgroundimage +=
                        backgroundimage !== ''
                            ? ', url(' + tmp + ')'
                            : 'url(' + tmp + ')';
                }
            }
        }
        return backgroundimage;
    };

    this.toString = function () {
        var tmp = 'mező ';
        var value = this.ertek;

        var backgroundimage = '';
        if (g_meghatarozasJeloles) {
            backgroundimage = this.getBackgroundImage(g_mezoIrany);
        } else {
            backgroundimage = this.getBackgroundImage(g_mezoIrany, true);
        }
        if (backgroundimage !== '') {
            this.stilus += 'background-image:' + backgroundimage + '; ';
        }
        if (this.ismert) {
            this.stilus += 'font-weight:bold; ';
        }

        if (this.fekete) {
            tmp += 'fekete ';
            value = '';
        }
        if (this.abra) {
            tmp += 'ábra ';
            value = '';
        }
        if (this.ures) {
            tmp += 'üres ';
            value = '';
        }
        if (this.tipus !== null) {
            tmp += this.tipus + ' ';
        }
        if (
            this.vvonal ||
            this.fvonal ||
            this.vgyengevonal ||
            this.fgyengevonal
        ) {
            if (
                (this.vvonal || this.vgyengevonal) &&
                (this.fvonal || this.fgyengevonal)
            ) {
                tmp += 'vvonal fvonal ';
            } else if (this.vvonal || this.vgyengevonal) {
                tmp += 'vvonal ';
            } else {
                tmp += 'fvonal ';
            }
        }
        tmp = tmp !== '' ? ' class="' + tmp.rtrim() + '"' : '';

        var tmp2 =
            this.stilus !== '' ? ' style="' + this.stilus.rtrim() + '"' : '';

        return (
            '<div id="' +
            this.mezoID +
            '"' +
            tmp +
            tmp2 +
            '>' +
            value +
            '</div>'
        );
    };

    this.toXML = function () {
        var str = '';
        if (this.vvonal || this.fvonal) {
            if (this.vvonal && this.fvonal) {
                str = 'vf';
            } else if (this.vvonal) {
                str = 'v';
            } else {
                str = 'f';
            }
            str = ' vonal="' + str + '"';
        }

        var str2 = '';
        if (this.vgyengevonal || this.fgyengevonal) {
            if (this.vgyengevonal && this.fgyengevonal) {
                str2 = 'vf';
            } else if (this.vgyengevonal) {
                str2 = 'v';
            } else {
                str2 = 'f';
            }
            str += ' gyengevonal="' + str2 + '"';
        }

        if (this.hasVmeghatarozas()) {
            str +=
                ' vkifejezés="' +
                this.vKifejezes +
                '" vmeghatározás="' +
                this.vMeghatarozas +
                '"';
        }
        if (this.hasFmeghatarozas()) {
            str +=
                ' fkifejezés="' +
                this.fKifejezes +
                '" fmeghatározás="' +
                this.fMeghatarozas +
                '"';
        }
        if (this.hasVHmeghatarozas()) {
            str +=
                ' vhkifejezés="' +
                this.vhKifejezes +
                '" vhmeghatározás="' +
                this.vhMeghatarozas +
                '" hídpozíció="' +
                this.hidpozicio +
                '"';
        }
        if (this.hasFHmeghatarozas()) {
            str +=
                ' fhkifejezés="' +
                this.fhKifejezes +
                '" fhmeghatározás="' +
                this.fhMeghatarozas +
                '" hídpozíció="' +
                this.hidpozicio +
                '"';
        }

        if (this.tipus !== null) {
            str += ' típus="' + this.tipus + '"';
        }

        if (this.ismert) {
            str += ' ismert="1"';
        }

        return (
            '<mező' +
            str +
            '>' +
            (this.ertek != null ? this.ertek : '') +
            '</mező>'
        );
    };
}

function Halozat(index) {
    var h = [];

    var minID = 0;
    var maxID = 0;

    var sor = 0;
    var oszlop = 0;

    var vbehuzas = 0;
    var fbehuzas = 0;

    var id = index ? 'halozat-' + index : 'halozat-0';

    this.push = function (e) {
        h.push(e);
    };
    this.pop = function () {
        h.pop();
    };
    this.get = function (i) {
        return h[i - minID];
    };

    this.toString = function () {
        var i;

        var tmp = '';
        for (i = 0; i < vbehuzas; i++) {
            tmp += '<div class="számosmező">&nbsp;</div>';
        }
        for (i = vbehuzas; i < oszlop - vbehuzas; i++) {
            tmp += '<div class="számosmező">' + (i - vbehuzas + 1) + '</div>';
        }
        for (i = oszlop - vbehuzas; i < oszlop; i++) {
            tmp += '<div class="számosmező">&nbsp;</div>';
        }

        tmp =
            '<div style="clear:both; float:left;">' +
            (index % 2 == 0
                ? '<div class="számosmező" style="border-right:1px solid rgb(255, 255, 255);">&nbsp;</div>'
                : '') +
            tmp +
            '</div>';

        var tmp2 = '';
        for (i = 0; i < fbehuzas; i++) {
            tmp2 +=
                '<div class="számosmező" style="clear:both; float:left;">&nbsp;</div>';
        }
        for (i = fbehuzas; i < sor - fbehuzas; i++) {
            tmp2 +=
                '<div class="számosmező" style="clear:both; float:left;">' +
                (i - fbehuzas + 1) +
                '</div>';
        }
        for (i = sor - fbehuzas; i < sor; i++) {
            tmp2 +=
                '<div class="számosmező" style="clear:both; float:left;">&nbsp;</div>';
        }

        var tmp3 = '';
        if (index % 2 == 0) {
            tmp2 = '<div style="clear:both; float:left;">' + tmp2 + '</div>';
            tmp3 = '<div>&nbsp;</div>';
        } else {
            tmp3 = '<div style="float:left;">' + tmp2 + '</div>';
            tmp2 =
                '<div class="számosmező" style="border-right:1px solid rgb(255, 255, 255);">&nbsp;</div>';
        }

        var halozat;
        if (index % 2 == 0) {
            halozat = '<div style="float:left;">' + h.join('') + '</div>';
        } else {
            halozat =
                '<div style="float:left; clear:both;">' + h.join('') + '</div>';
        }

        return (
            '<div id="' +
            id +
            '" class="hálózat">' +
            tmp +
            tmp2 +
            halozat +
            tmp3 +
            '</div>'
        );
    };

    this.initBehuzas = function () {
        var i = 0;
        while (i < h.length && !h[i].isFgyengevonal()) {
            i++;
        }
        vbehuzas = i == h.length ? 0 : (i + 1) % oszlop;

        i = 0;
        while (i < h.length && !h[i].isVgyengevonal()) {
            i++;
        }
        fbehuzas = i == h.length ? 0 : i % oszlop;
        fbehuzas += i > h.length / sor && fbehuzas != 0 ? 1 : 0;
    };

    this.initVonalak = function () {
        var i, j;

        i = 0;
        while (i < h.length) {
            for (j = 0; j < +vbehuzas; j++) {
                h[i + j].letiltFVonal();
                h[i + j].letiltVVonal();
            }
            for (j = oszlop - vbehuzas - 1; j < oszlop; j++) {
                if (j >= oszlop - vbehuzas) {
                    h[i + j].letiltVVonal();
                }
                h[i + j].letiltFVonal();
            }
            i += oszlop;
        }

        for (i = 0; i < oszlop; i++) {
            for (j = 0; j < fbehuzas; j += oszlop) {
                h[i + j].letiltVVonal();
                h[i + j].letiltFVonal();
            }
            for (
                j = h.length - fbehuzas * oszlop - oszlop;
                j < h.length;
                j += oszlop
            ) {
                if (j >= h.length - fbehuzas * oszlop) {
                    h[i + j].letiltFVonal();
                }
                h[i + j].letiltVVonal();
            }
        }
    };

    this.getVMeghatarozasok = function () {
        var tomb = [];
        var str = '';
        var cls = '';
        var ertek = '';

        var i;
        for (i = 0; i < h.length; i++) {
            if (h[i].hasVmeghatarozas()) {
                cls = h[i].hasVkifejezesHiba() ? ' class="hiba"' : '';

                ertek =
                    str != ''
                        ? h[i].getVmeghatarozas()
                        : h[i].getVmeghatarozas().capitalize();

                if (h[i].isSzines()) {
                    ertek = '<b>' + ertek + '</b>';
                }

                str += str != '' ? ' – ' : '';

                str +=
                    '<a href="javascript:ugrasMezore(' +
                    (i + this.getminID()) +
                    ', true);" desc="' +
                    h[i].getVkifejezes() +
                    '"' +
                    cls +
                    '>' +
                    ertek +
                    '</a>';
            }
            if (
                (i + 1) % oszlop == 0 &&
                (i + 1) / oszlop > fbehuzas &&
                (i + 1) / oszlop <= sor - fbehuzas
            ) {
                tomb.push(str);
                str = '';
            }
        }

        return tomb;
    };

    this.getFMeghatarozasok = function () {
        var tomb = [];
        var str = '';
        var cls = '';
        var ertek = '';

        var i, j;
        for (i = 0; i < oszlop; i++) {
            for (j = 0; j < h.length; j += oszlop) {
                if (h[i + j].hasFmeghatarozas()) {
                    cls = h[i + j].hasFkifejezesHiba() ? ' class="hiba"' : '';

                    ertek =
                        str != ''
                            ? h[i + j].getFmeghatarozas()
                            : h[i + j].getFmeghatarozas().capitalize();

                    if (h[i].isSzines()) {
                        ertek = '<b>' + ertek + '</b>';
                    }

                    str += str != '' ? ' – ' : '';

                    str +=
                        '<a href="javascript:ugrasMezore(' +
                        (i + j + this.getminID()) +
                        ', false);" desc="' +
                        h[i + j].getFkifejezes() +
                        '"' +
                        cls +
                        '>' +
                        ertek +
                        '</a>';
                }
            }
            if (j % oszlop == 0 && i + 1 > vbehuzas && i < oszlop - vbehuzas) {
                tomb.push(str);
                str = '';
            }
        }

        return tomb;
    };

    this.getVHMeghatarozasok = function () {
        var tomb = [];
        var str = '';
        var cls = '';
        var ertek = '';

        var i;
        for (i = 0; i < h.length; i++) {
            if (h[i].hasVHmeghatarozas()) {
                cls = h[i].hasVHkifejezesHiba() ? ' class="hiba"' : '';

                ertek =
                    str != ''
                        ? h[i].getVHmeghatarozas()
                        : h[i].getVHmeghatarozas().capitalize();
                str += str != '' ? ' – ' : '';

                str +=
                    '<a href="javascript:ugrasMezore(' +
                    h[i].getHidPozicio() +
                    ', true);" desc="' +
                    h[i].getVHkifejezes() +
                    '"' +
                    cls +
                    '>' +
                    ertek +
                    '</a>';
            }
            if (
                (i + 1) % oszlop == 0 &&
                (i + 1) / oszlop > fbehuzas &&
                (i + 1) / oszlop <= sor - fbehuzas
            ) {
                tomb.push(str);
                str = '';
            }
        }

        return tomb;
    };

    this.getFHMeghatarozasok = function () {
        var tomb = [];
        var str = '';
        var cls = '';
        var ertek = '';

        var i, j;
        for (i = 0; i < oszlop; i++) {
            for (j = 0; j < h.length; j += oszlop) {
                if (h[i + j].hasFHmeghatarozas()) {
                    cls = h[i + j].hasFHkifejezesHiba() ? ' class="hiba"' : '';

                    ertek =
                        str != ''
                            ? h[i + j].getFHmeghatarozas()
                            : h[i + j].getFHmeghatarozas().capitalize();
                    str += str != '' ? ' – ' : '';

                    str +=
                        '<a href="javascript:ugrasMezore(' +
                        h[i + j].getHidPozicio() +
                        ', false);" desc="' +
                        h[i + j].getFHkifejezes() +
                        '"' +
                        cls +
                        '>' +
                        ertek +
                        '</a>';
                }
            }
            if (j % oszlop == 0 && i + 1 > vbehuzas && i < oszlop - vbehuzas) {
                tomb.push(str);
                str = '';
            }
        }

        return tomb;
    };

    this.setminID = function (v) {
        minID = v;
    };
    this.getminID = function () {
        return minID;
    };
    this.setmaxID = function (v) {
        maxID = v;

        this.setOszlop((maxID - minID + 1) / sor);
    };
    this.getmaxID = function () {
        return maxID;
    };
    this.setSor = function (v) {
        sor = v;
    };
    this.getSor = function () {
        return sor;
    };

    this.setOszlop = function (v) {
        oszlop = v;
    };
    this.getOszlop = function () {
        return oszlop;
    };

    this.elozoMezo = function (i) {
        if (i === minID) {
            return maxID;
        }
        i--;
        return i;
    };
    this.kovetkezoMezo = function (i) {
        if (i === maxID) {
            return minID;
        }
        i++;
        return i;
    };
    this.felsoMezo = function (i) {
        if (i === minID) {
            return maxID;
        }

        i -= oszlop;
        if (i < minID) {
            i += sor * oszlop;
            i--;
        }
        return i;
    };
    this.alsoMezo = function (i) {
        if (i === maxID) {
            return minID;
        }

        i += oszlop;
        if (i > maxID) {
            i -= sor * oszlop;
            i++;
        }
        return i;
    };
    this.vmezoSelect = function (
        index,
        nincsEllenorzes,
        nincsCsatornaVizsgalat,
    ) {
        var tomb = [];

        var hid = nincsCsatornaVizsgalat
            ? h[index - minID].isHid()
            : h[index - minID].isHid() && this.isVcsatorna(index);

        if (
            nincsEllenorzes ||
            (!h[index - minID].isUres() &&
                !h[index - minID].isFekete() &&
                !h[index - minID].isAbra())
        ) {
            var i = index - minID;
            while (
                i < (Math.floor((index - minID) / oszlop) + 1) * oszlop - 1 &&
                (!h[i].isFvonal() || hid) &&
                !h[i + 1].isFekete() &&
                !h[i + 1].isAbra() &&
                !h[i + 1].isUres() &&
                (!hid || h[i + 1].isHid())
            ) {
                tomb.push(i + 1 + minID);
                i++;
            }
            i = index - 1 - minID;
            while (
                i > Math.floor((index - minID) / oszlop) * oszlop - 1 &&
                (!h[i].isFvonal() || hid) &&
                !h[i].isFekete() &&
                !h[i].isAbra() &&
                !h[i].isUres() &&
                (!hid || h[i].isHid())
            ) {
                tomb.push(i + minID);
                i--;
            }
        }
        return tomb;
    };

    this.fmezoSelect = function (
        index,
        nincsEllenorzes,
        nincsCsatornaVizsgalat,
    ) {
        var tomb = [];

        var hid = nincsCsatornaVizsgalat
            ? h[index - minID].isHid()
            : h[index - minID].isHid() && this.isFcsatorna(index);

        if (
            nincsEllenorzes ||
            (!h[index - minID].isUres() &&
                !h[index - minID].isFekete() &&
                !h[index - minID].isAbra())
        ) {
            var i = index - minID;
            while (
                i + oszlop <= maxID - minID &&
                (!h[i].isVvonal() || hid) &&
                !h[i + oszlop].isFekete() &&
                !h[i + oszlop].isAbra() &&
                !h[i + oszlop].isUres() &&
                (!hid || h[i + oszlop].isHid())
            ) {
                tomb.push(i + oszlop + minID);
                i += oszlop;
            }
            i = index - oszlop - minID;
            while (
                i > -1 &&
                (!h[i].isVvonal() || hid) &&
                !h[i].isFekete() &&
                !h[i].isAbra() &&
                !h[i].isUres() &&
                (!hid || h[i].isHid())
            ) {
                tomb.push(i + minID);
                i -= oszlop;
            }
        }
        return tomb;
    };

    this.isVcsatorna = function (index) {
        var tomb = this.vmezoSelect(index, false, true);
        tomb.push(index);
        var vonal = [];
        var i;

        for (i = 0; i < tomb.length; i++) {
            if (h[tomb[i] - minID].isFvonal()) {
                vonal.push(tomb[i]);
            }
        }
        vonal.sort(rendezes);

        return (
            vonal.length == 2 &&
            vonal[0] < index - minID &&
            index - minID <= vonal[1]
        );
    };

    this.isFcsatorna = function (index) {
        var tomb = this.fmezoSelect(index, false, true);
        tomb.push(index);
        var vonal = [];
        var i;

        for (i = 0; i < tomb.length; i++) {
            if (h[tomb[i] - minID].isVvonal()) {
                vonal.push(tomb[i]);
            }
        }
        vonal.sort(rendezes);

        return (
            vonal.length == 2 &&
            vonal[0] < index - minID &&
            index - minID <= vonal[1]
        );
    };

    this.toXML = function () {
        var tmp =
            '<hálózat vbehúzás="' + vbehuzas + '" fbehúzás="' + fbehuzas + '">';

        var i;
        for (i = 0; i < h.length; i++) {
            if (i % oszlop === 0) {
                tmp += '<sor>';
            }
            tmp += h[i].toXML();
            if (i % oszlop === oszlop - 1) {
                tmp += '</sor>';
            }
        }
        return tmp + '</hálózat>';
    };

    this.checkMeghatarozasHibak = function () {
        var result = false;

        var i;
        var tomb;

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.vmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            if (tomb.length > 1) {
                if (
                    !this.get(tomb[0]).hasVmeghatarozas() &&
                    !this.get(tomb[0]).hasVHmeghatarozas()
                ) {
                    result = true;
                }
            }

            i += tomb.length;
        }

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.fmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            if (tomb.length > 1) {
                if (
                    !this.get(tomb[0]).hasFmeghatarozas() &&
                    !this.get(tomb[0]).hasFHmeghatarozas()
                ) {
                    result = true;
                }
            }

            i++;
        }

        return result;
    };

    this.checkKifejezesHibak = function () {
        var i;
        var result = false;

        i = 0;
        while (i < h.length && !result) {
            result = h[i].hasKifejezesHiba();

            i++;
        }

        return result;
    };

    this.initKifejezesHibak = function () {
        var i, j;
        var tomb;
        var tmp;

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.vmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            tmp = '';
            for (j = 0; j < tomb.length; j++) {
                tmp += this.get(tomb[j]).getErtek();
            }
            if (
                this.get(tomb[0]).hasVmeghatarozas() &&
                this.get(tomb[0]).getVkifejezes() != tmp &&
                !this.isVcsatorna(i)
            ) {
                this.get(tomb[0]).enableVkifejezesHiba();
            }
            i += tomb.length;
        }

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.vmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            tmp = '';
            for (j = 0; j < tomb.length; j++) {
                tmp += this.get(tomb[j]).getErtek();
            }
            if (
                this.get(tomb[0]).hasVHmeghatarozas() &&
                this.get(tomb[0]).getVHkifejezes() != tmp
            ) {
                this.get(tomb[0]).enableVHkifejezesHiba();
            }

            i++;
        }

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.fmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            tmp = '';
            for (j = 0; j < tomb.length; j++) {
                tmp += this.get(tomb[j]).getErtek();
            }

            if (
                this.get(tomb[0]).hasFmeghatarozas() &&
                this.get(tomb[0]).getFkifejezes() != tmp &&
                !this.isFcsatorna(i)
            ) {
                this.get(tomb[0]).enableFkifejezesHiba();
            }

            i++;
        }

        i = this.getminID();
        while (i < this.getmaxID()) {
            tomb = [];
            tomb = this.fmezoSelect(i);
            tomb.push(i);
            tomb.sort(rendezes);

            tmp = '';
            for (j = 0; j < tomb.length; j++) {
                tmp += this.get(tomb[j]).getErtek();
            }
            if (
                this.get(tomb[0]).hasFHmeghatarozas() &&
                this.get(tomb[0]).getFHkifejezes() != tmp
            ) {
                this.get(tomb[0]).enableFHkifejezesHiba();
            }
            i++;
        }
    };
}

function Rejtveny(t) {
    var tipus = t;

    var r = [];
    var hossz = 0;
    var megoldas = null;
    var grafika = [];
    var adatlap = '';
    var szinek = [];

    this.push = function (e) {
        r.push(e);
    };

    this.get = function (i) {
        return r[i];
    };

    this.getTipus = function () {
        return tipus;
    };

    this.setHossz = function (v) {
        hossz = v;
    };
    this.getHossz = function () {
        return hossz;
    };

    this.setMegoldas = function (v) {
        megoldas = v;
    };

    this.getMegoldas = function () {
        return megoldas;
    };

    this.setGrafika = function (v) {
        var kep, i;

        var item = v.getElementsByTagName('kép');

        for (i = 0; i < item.length; i++) {
            kep = new Kep(
                item[i].getAttribute('helye'),
                item[i].childNodes[0].nodeValue,
            );

            grafika.push(kep);
        }
    };
    this.getGrafika = function () {
        return grafika.slice();
    };

    this.delGrafika = function () {
        grafika = [];
    };
    this.hasGrafika = function () {
        return grafika.length > 0;
    };

    this.getMaszkImage = function (id) {
        var helye = null;
        var bg = '';

        var i = 0;
        while (i < grafika.length && id != helye) {
            helye = grafika[i].getHelye();
            bg = grafika[i].getBackgroundImage();

            i++;
        }

        return bg;
    };

    this.setSzinek = function (s1, s2, s3) {
        szinek[0] = s1;
        szinek[1] = s2;
        szinek[2] = s3;
    };

    this.getKeretSzin = function () {
        return szinek[0];
    };
    this.getHatterSzin = function () {
        return szinek[1];
    };
    this.getBetuSzin = function () {
        return szinek[2];
    };

    this.setAdatlap = function (v) {
        adatlap = v;
    };

    this.countHalozat = function () {
        return r.length;
    };

    this.getHalozat = function (index) {
        var i;

        for (i = 0; i < r.length; i++) {
            if (r[i].getminID() <= index && r[i].getmaxID() >= index) {
                return r[i];
            }
        }
    };

    this.getMezo = function (index) {
        return this.getHalozat(index).get(index);
    };

    this.getNextMezo = function (index) {
        var minID =
            this.getNextHalozat(index).getminID() -
            this.getHalozat(index).getminID();

        return this.getNextHalozat(index).get(index + minID);
    };

    this.getNextHalozat = function (index) {
        var i;
        var kov = 0;

        for (i = 0; i < r.length; i++) {
            if (r[i].getminID() <= index && r[i].getmaxID() >= index) {
                kov = i + 1;
            }
        }
        if (kov === r.length) {
            kov = 0;
        }

        return r[kov];
    };

    this.initKifejezesHibak = function () {
        var i;

        for (i = 0; i < r.length; i++) {
            r[i].initKifejezesHibak();
        }
    };

    this.initBehuzas = function () {
        var i;

        for (i = 0; i < r.length; i++) {
            r[i].initBehuzas();
        }
    };

    this.initVonalak = function () {
        var i;

        for (i = 0; i < r.length; i++) {
            r[i].initVonalak();
        }
    };

    this.getMeghatarozasok = function () {
        var vegjel = ['!', '?', '.'];

        var i, j;
        var tomb2 = [];

        var vtomb = r[0].getVMeghatarozasok();
        for (i = 0; i < vtomb.length; i++) {
            vtomb[i] = ' <b>' + (i + 1) + '.</b> ' + vtomb[i];
        }
        for (i = 1; i < r.length; i++) {
            tomb2 = r[i].getVMeghatarozasok();
            for (j = 0; j < tomb2.length; j++) {
                vtomb[j] += ' – ' + tomb2[j];
            }
        }

        for (i = 0; i < vtomb.length; i++) {
            if (!vegjel.inTomb(vtomb[i].charAt(vtomb[i].length - 5))) {
                vtomb[i] += '.';
            }
        }

        var tmp1 = '<b>VÍZSZINTES:</b> ' + vtomb.join('');

        var ftomb = r[0].getFMeghatarozasok();
        for (i = 0; i < ftomb.length; i++) {
            ftomb[i] = ' <b>' + (i + 1) + '.</b> ' + ftomb[i];
        }
        for (i = 1; i < r.length; i++) {
            tomb2 = r[i].getFMeghatarozasok();
            for (j = 0; j < tomb2.length; j++) {
                ftomb[j] += ' – ' + tomb2[j];
            }
        }

        for (i = 0; i < ftomb.length; i++) {
            if (!vegjel.inTomb(ftomb[i].charAt(ftomb[i].length - 5))) {
                ftomb[i] += '.';
            }
        }

        var tmp2 = '<b>FÜGGŐLEGES:</b> ' + ftomb.join('');

        var tmp3 = '';

        var vhtomb = r[0].getVHMeghatarozasok();
        if (vhtomb.join('') != '') {
            for (i = 0; i < vhtomb.length; i++) {
                vhtomb[i] = ' <b>' + (i + 1) + '.</b> ' + vhtomb[i];
            }
            for (i = 1; i < r.length; i++) {
                tomb2 = r[i].getVHMeghatarozasok();
                for (j = 0; j < tomb2.length; j++) {
                    vhtomb[j] += ' – ' + tomb2[j];
                }
            }
            for (i = 0; i < vhtomb.length; i++) {
                if (!vegjel.inTomb(vhtomb[i].charAt(vhtomb[i].length - 5))) {
                    vhtomb[i] += '.';
                }
            }

            tmp3 = '<b>A hidak meghatározásai:</b> ' + vhtomb.join('');
        }

        var tmp4 = '';

        var fhtomb = r[0].getFHMeghatarozasok();
        if (fhtomb.join('') != '') {
            for (i = 0; i < fhtomb.length; i++) {
                fhtomb[i] = ' <b>' + (i + 1) + '.</b> ' + fhtomb[i];
            }
            for (i = 1; i < r.length; i++) {
                tomb2 = r[i].getFHMeghatarozasok();
                for (j = 0; j < tomb2.length; j++) {
                    fhtomb[j] += ' – ' + tomb2[j];
                }
            }

            for (i = 0; i < fhtomb.length; i++) {
                if (!vegjel.inTomb(fhtomb[i].charAt(fhtomb[i].length - 5))) {
                    fhtomb[i] += '.';
                }
            }

            tmp4 = '<b>A hidak meghatározásai:</b> ' + fhtomb.join('');
        }

        return [tmp1, tmp2, tmp3, tmp4];
    };

    this.checkMeghatarozasHibak = function () {
        var i;
        var result = false;

        i = 0;
        while (i < r.length && !result) {
            result = r[i].checkMeghatarozasHibak();

            i++;
        }

        return result;
    };

    this.checkKifejezesHibak = function () {
        var i;
        var result = false;

        i = 0;
        while (i < r.length && !result) {
            result = r[i].checkKifejezesHibak();

            i++;
        }

        return result;
    };

    this.toString = function () {
        return r.join('');
    };

    this.toXML = function () {
        var tmp = '<?xml version="1.0" encoding="utf-8"?><' + tipus + '>';

        tmp += '<adatlap>' + adatlap + '</adatlap>';

        var i;

        for (i = 0; i < r.length; i++) {
            tmp += r[i].toXML();
        }

        var szinSemak =
            ' keretszín="' +
            szinek[0] +
            '" háttérszín="' +
            szinek[1] +
            '" betűszín="' +
            szinek[2] +
            '"';

        if (grafika.length > 0) {
            tmp +=
                '<grafika' + szinSemak + '>' + grafika.join('') + '</grafika>';
        } else {
            tmp += '<grafika' + szinSemak + '></grafika>';
        }

        if (megoldas != null) tmp += '<megoldás>' + megoldas + '</megoldás>';

        return tmp + '</' + tipus + '>';
    };
}

function Kep(helye, ertek) {
    this.helye = helye;
    this.ertek = ertek;

    this.getHelye = function () {
        return this.helye;
    };

    this.getBackgroundImage = function () {
        return 'url(' + this.getBase64Img() + ')';
    };
    this.getBase64Img = function () {
        return 'data:image/jpeg;base64,' + this.ertek;
    };
    this.toString = function () {
        return '<kép helye="' + this.helye + '">' + this.ertek + '</kép>';
    };
}

function Beallitas(tipus, xmlString) {
    this.megfejtes = false;
    this.hid = false;
    this.ures = false;
    this.fekete = false;
    this.abra = false;
    this.megoldas = false;

    this.getBoolean = function (v) {
        if (v === '1') {
            return true;
        }
        return false;
    };

    this.setBeallitas = function (tipus, xmlString) {
        var xmlDOM = str2xmlDOM(xmlString);

        var element = xmlDOM.getElementsByTagName(tipus);

        if (element.length > 0) {
            this.megfejtes = this.getBoolean(
                element[0].getAttribute('megfejtés'),
            );
            this.megoldas = this.getBoolean(
                element[0].getAttribute('megoldás'),
            );
            this.hid = this.getBoolean(element[0].getAttribute('híd'));
            this.ures = this.getBoolean(element[0].getAttribute('üres'));
            this.fekete = this.getBoolean(element[0].getAttribute('fekete'));
            this.abra = this.getBoolean(element[0].getAttribute('ábra'));
        }
    };

    this.setBeallitas(tipus, xmlString);

    this.allowMegfejtes = function () {
        return this.megfejtes;
    };
    this.allowMegoldas = function () {
        return this.megoldas;
    };
    this.allowHid = function () {
        return this.hid;
    };
    this.allowUres = function () {
        return this.ures;
    };
    this.allowFekete = function () {
        return this.fekete;
    };
    this.allowAbra = function () {
        return this.abra;
    };
}

function Menu() {
    this.getRejtvenyGombok = function () {
        var tmp = '';

        if (g_mentve) {
            tmp +=
                '<a href="javascript:mentes();" desc="Mentés (Ctrl+S)"><img src="' +
                g_imgUtvonal +
                'gomb_mentes.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:mentes();" desc="Mentés (Ctrl+S)"><img src="' +
                g_imgUtvonal +
                'gomb_mentes_x.png"></a>';
        }

        if (g_elozmeny.hasVisszavonasGomb()) {
            tmp +=
                '<a href="javascript:elozmenyVisszavonas();" desc="' +
                g_elozmeny.getVisszavonasFelirat() +
                '"><img src="' +
                g_imgUtvonal +
                'gomb_visszavonas.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:" class="inaktiv" desc="Visszavonás"><img src="' +
                g_imgUtvonal +
                'gomb_visszavonas.png"></a>';
        }

        if (g_elozmeny.hasIsmetGomb()) {
            tmp +=
                '<a href="javascript:elozmenyIsmet();" desc="' +
                g_elozmeny.getIsmetFelirat() +
                '"><img src="' +
                g_imgUtvonal +
                'gomb_ismet.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:" class="inaktiv" desc="Ismét"><img src="' +
                g_imgUtvonal +
                'gomb_ismet.png"></a>';
        }

        tmp += '</span><span class="blokk">';

        if (g_epitoMod) {
            tmp +=
                '<a href="javascript:showKereses();" desc="Kifejezés és meghatározás keresés"><img src="' +
                g_imgUtvonal +
                'gomb_keres.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:" class="inaktiv" desc="Kifejezés és meghatározás keresés"><img src="' +
                g_imgUtvonal +
                'gomb_keres.png"></a>';
        }

        if (g_ellenorizve) {
            tmp +=
                '<a href="javascript:unEllenorzes();" class="aktiv" desc="Ellenőrzés"><img src="' +
                g_imgUtvonal +
                'gomb_ellenorzes.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:showEllenorzes();" desc="Ellenőrzés"><img src="' +
                g_imgUtvonal +
                'gomb_ellenorzes.png"></a>';
        }

        return tmp;
    };

    this.getMezoGombok = function () {
        var tmp = '';
        var aktiv = '';

        if (g_keretszin != g_rejtveny.getKeretSzin()) {
            var http = new XMLHttpRequest();

            http.open(
                'GET',
                'index.php?oldal=szinesgomb&hatterszin=' +
                    g_rejtveny.getHatterSzin().slice(1) +
                    '&betu=M',
                false,
            );
            http.send();

            g_base64Szines = http.responseText;
            g_keretszin = g_rejtveny.getKeretSzin();
        }

        aktiv = setSzines(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setSzines(g_aktualisObj);" desc="Megfejtés betűje (Ctrl+M)"' +
            aktiv +
            '><img src="' +
            g_base64Szines +
            '"></a>';

        if (g_base64Hid == '') {
            var http = new XMLHttpRequest();

            http.open(
                'GET',
                'index.php?oldal=szinesgomb&hatterszin=39c2e4&betu=H',
                false,
            );
            http.send();

            g_base64Hid = http.responseText;
        }

        aktiv = setHid(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setHid(g_aktualisObj);" desc="Híd betűje (Ctrl+H)"' +
            aktiv +
            '><img src="' +
            g_base64Hid +
            '"></a>';

        aktiv = setUres(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setUres(g_aktualisObj);" desc="Üresen marad (Ctrl+A)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_ures.png"></a>';

        aktiv = setAbra(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setAbra(g_aktualisObj);" desc="Ábra helye (Ctrl+A)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_abra.png"></a>';

        aktiv = setFekete(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setFekete(g_aktualisObj);" desc="Fekete négyzet (Ctrl+N)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_fekete.png"></a>';

        aktiv = setVvonal(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setVvonal(g_aktualisObj);" desc="Vízszintes vonal (Ctrl+V)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_vvonal.png"></a>';

        aktiv = setFvonal(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setFvonal(g_aktualisObj);" desc="Föggőleges vonal (Ctrl+F)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_fvonal.png"></a>';

        aktiv = setIsmert(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setIsmert(g_aktualisObj);" desc="Ismert mező (Ctrl+I)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_ismert.png"></a>';

        return tmp;
    };

    this.getSzerkesztoGombok = function () {
        var tmp = '';
        var aktiv = '';

        aktiv = szinkronizal(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:szinkronizal(g_aktualisObj);" desc="Szinkronizált szerkesztés (Ctrl+E)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_szinkron.png"></a>';

        aktiv = changeMezoIrany(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:changeMezoIrany(g_aktualisObj);" desc="Függőleges kijelölési irány (Space)"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_mezoirany.png"></a>';

        return tmp;
    };

    this.getNezetGombok = function () {
        var aktiv = '';
        var tmp = '';

        aktiv = ismertJelolesek(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:ismertJelolesek(g_aktualisObj);" desc="Ismert mezők"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_ismertjeloles.png"></a>';

        aktiv = jelolesek(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:jelolesek(g_aktualisObj);" desc="Meghatározások helyei"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_jeloles.png"></a>';

        aktiv = setMaszk(g_aktualisObj, true);
        tmp +=
            '<a href="javascript:setMaszk(g_aktualisObj);" desc="Maszk megjelenítése"' +
            aktiv +
            '><img src="' +
            g_imgUtvonal +
            'gomb_setmaszk.png"></a>';

        return tmp;
    };

    this.getGrafikaiGombok = function () {
        var tmp = '';

        if (g_beallitas.allowAbra()) {
            tmp +=
                '<a href="javascript:changeMaszk(g_aktualisObj);" desc="Maszk feltöltése"><img src="' +
                g_imgUtvonal +
                'gomb_changemaszk.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:" class="inaktiv" desc="Maszk feltöltése"><img src="' +
                g_imgUtvonal +
                'gomb_changemaszk.png"></a>';
        }

        if (g_epitoMod) {
            tmp +=
                '<a href="javascript:changeSzinsema(g_aktualisObj);" desc="Színséma kiválasztása"><img src="' +
                g_imgUtvonal +
                'gomb_szinsema.png"></a>';
        } else {
            tmp +=
                '<a href="javascript:" class="inaktiv" desc="Színséma kiválasztása"><img src="' +
                g_imgUtvonal +
                'gomb_szinsema.png"></a>';
        }

        return tmp;
    };

    this.getEgyebGombok = function () {
        return (
            '<a href="javascript:showInformacio(g_aktualisObj);" desc="Információ"><img src="' +
            g_imgUtvonal +
            'gomb_info.png"></a>'
        );
    };

    this.toString = function () {
        this.updateKijeloltKifejezes();

        var tmp = '';

        tmp += '<span class="blokk">' + this.getRejtvenyGombok() + '</span>';
        tmp += '<span class="blokk">' + this.getMezoGombok() + '</span>';
        tmp += '<span class="blokk">' + this.getSzerkesztoGombok() + '</span>';
        tmp += '<span class="blokk">' + this.getNezetGombok() + '</span>';
        tmp += '<span class="blokk">' + this.getGrafikaiGombok() + '</span>';
        tmp += '<span class="blokk">' + this.getEgyebGombok() + '</span>';

        return tmp;
    };

    this.updateKijeloltKifejezes = function () {
        var objektum = g_rejtveny.getMezo(getElsoMezoID(g_aktualisTomb));

        var kifejezes = getKifejezes(getTeljesTomb(g_aktualisTomb));

        var str = '';
        var str2 = '';

        if (g_mezoIrany) {
            str2 = objektum.hasVkifejezesHiba()
                ? objektum.getVkifejezes()
                : kifejezes;
        } else {
            str2 = objektum.hasFkifejezesHiba()
                ? objektum.getFkifejezes()
                : kifejezes;
        }

        var i;
        for (i = 0; i < kifejezes.length; i++) {
            if (kifejezes[i] === str2[i]) {
                str += '<b>' + kifejezes[i] + '</b>';
            } else {
                str +=
                    '<b style="color:rgb(200, 0, 0);">' + kifejezes[i] + '</b>';
            }
        }

        document.getElementById('rejtveny_fejlec').innerHTML = str;
    };
}

function MegoldasMenu(megoldas) {
    this.megoldas = '';

    this.setMegoldas = function (v) {
        this.megoldas = v;
    };

    this.setMegoldas(megoldas);

    this.toString = function () {
        var tmp = '';

        if (g_beallitas.allowMegoldas()) {
            tmp += '<label for="textareaMegoldas">Megoldási segítség</label>';
            tmp +=
                '<textarea name="textareaMegoldas" id="textareaMegoldas" rows="5">' +
                this.megoldas +
                '</textarea>';

            return '<form><fieldset>' + tmp + '</fieldset></form>';
        }
        return tmp;
    };
}

function MeghMenu() {
    var vizszintes = true;

    var objektum = undefined;
    var aktObjektum = undefined;

    this.setMezoDir = function (v) {
        vizszintes = v;
    };

    this.setMezoID = function (rejtveny, mezoID, aktMezoID) {
        objektum = rejtveny.getMezo(mezoID);
        aktObjektum = rejtveny.getMezo(aktMezoID);
    };

    this.enableHiba = function (kifejezes) {
        if (
            vizszintes &&
            objektum.hasVmeghatarozas() &&
            objektum.getVkifejezes() !== kifejezes
        ) {
            objektum.enableVkifejezesHiba();
        }
        if (objektum.getVkifejezes() === kifejezes) {
            objektum.disableVkifejezesHiba();
        }

        if (!vizszintes && objektum.hasFmeghatarozas()) {
            objektum.enableFkifejezesHiba();
        }
        if (objektum.getFkifejezes() === kifejezes) {
            objektum.disableFkifejezesHiba();
        }

        if (vizszintes && objektum.hasVHmeghatarozas()) {
            objektum.enableVHkifejezesHiba();
        }
        if (objektum.getVHkifejezes() === kifejezes) {
            objektum.disableVHkifejezesHiba();
        }
        if (!vizszintes && objektum.hasFHmeghatarozas()) {
            objektum.enableFHkifejezesHiba();
        }
        if (objektum.getFHkifejezes() === kifejezes) {
            objektum.disableFHkifejezesHiba();
        }
    };

    this.disableHiba = function () {
        if (vizszintes && objektum.hasVmeghatarozas()) {
            objektum.disableVkifejezesHiba();
        }
        if (!vizszintes && objektum.hasFmeghatarozas()) {
            objektum.disableFkifejezesHiba();
        }
        if (vizszintes && objektum.hasVHmeghatarozas()) {
            objektum.disableVHkifejezesHiba();
        }
        if (!vizszintes && objektum.hasFHmeghatarozas()) {
            objektum.disableFHkifejezesHiba();
        }
    };

    this.toString = function () {
        var tmp = '';

        if (
            objektum !== undefined &&
            aktObjektum !== undefined &&
            !objektum.isFekete() &&
            !objektum.isAbra() &&
            getTeljesTomb(g_aktualisTomb).length > 1
        ) {
            if (
                g_mezoIrany &&
                g_rejtveny
                    .getHalozat(g_aktualisMezoID)
                    .isVcsatorna(g_aktualisMezoID)
            ) {
                tmp +=
                    '<label for="textareaMeghatarozas">Vízszintes híd meghatározás</label>';
                tmp +=
                    '<textarea name="textareaMeghatarozas" id="textareaMeghatarozas" rows="5"></textarea>';
                tmp += objektum.hasVHkifejezesHiba()
                    ? '<p>Megváltozott kifejezés: <a href="javascript:setKifejezes(\'' +
                      objektum.getVHkifejezes() +
                      '\');" class="figyelmeztetes" desc="Eredeti: ' +
                      objektum.getVHkifejezes() +
                      '"><img src="' +
                      g_imgUtvonal +
                      'warning.png"></a></p>'
                    : '';
            } else if (
                g_rejtveny
                    .getHalozat(g_aktualisMezoID)
                    .isFcsatorna(g_aktualisMezoID)
            ) {
                tmp +=
                    '<label for="textareaMeghatarozas">Függőleges híd meghatározás</label>';
                tmp +=
                    '<textarea name="textareaMeghatarozas" id="textareaMeghatarozas" rows="5"></textarea>';
                tmp += objektum.hasFHkifejezesHiba()
                    ? '<p>Megváltozott kifejezés: <a href="javascript:setKifejezes(\'' +
                      objektum.getFHkifejezes() +
                      '\');" class="figyelmeztetes" desc="Eredeti: ' +
                      objektum.getFHkifejezes() +
                      '"><img src="' +
                      g_imgUtvonal +
                      'warning.png"></a></p>'
                    : '';
            } else {
                if (g_mezoIrany) {
                    tmp +=
                        '<label for="textareaMeghatarozas">Vízszintes meghatározás</label>';
                    tmp +=
                        '<textarea name="textareaMeghatarozas" id="textareaMeghatarozas" rows="5"></textarea>';
                    tmp += objektum.hasVkifejezesHiba()
                        ? '<p>Megváltozott kifejezés: <a href="javascript:setKifejezes(\'' +
                          objektum.getVkifejezes() +
                          '\');" class="figyelmeztetes" desc="Eredeti: ' +
                          objektum.getVkifejezes() +
                          '"><img src="' +
                          g_imgUtvonal +
                          'warning.png"></a></p>'
                        : '';
                } else {
                    tmp +=
                        '<label for="textareaMeghatarozas">Függőleges meghatározás</label>';
                    tmp +=
                        '<textarea name="textareaMeghatarozas" id="textareaMeghatarozas" rows="5" ></textarea>';
                    tmp += objektum.hasFkifejezesHiba()
                        ? '<p>Megváltozott kifejezés: <a href="javascript:setKifejezes(\'' +
                          objektum.getFkifejezes() +
                          '\');" class="figyelmeztetes" desc="Eredeti: ' +
                          objektum.getFkifejezes() +
                          '"><img src="' +
                          g_imgUtvonal +
                          'warning.png"></a></p>'
                        : '';
                }
            }

            return '<form><fieldset>' + tmp + '</fieldset></form>';
        }
        return tmp;
    };
}

function getRejtvenyKomponens() {
    var tmp = '<form>';
    tmp += '<div id="rejtveny_menusor"></div>';
    tmp += '<div id="rejtveny_komponens">';
    tmp += '<div id="rejtveny_fejlec">Töltés...</div>';
    tmp += '<div id="rejtveny"></div>';
    tmp += '<div id="rejtveny_leiras"></div>';
    tmp +=
        '<div id="rejtveny_lablec">' +
        '<div id="oszlop-0" class="oszlop2"></div>' +
        '<div id="oszlop-1" class="oszlop2"></div>' +
        '<div id="oszlop-2" class="oszlop2"></div>' +
        '<div id="oszlop-3" class="oszlop2"></div>' +
        '</div>';
    tmp += '</div>';
    tmp += '<div id="rejtveny_megoldassav"></div>';
    tmp += '<div id="rejtveny_meghatarozassav"></div>';
    tmp += '<div id="rejtveny_elozmenysav"></div>';
    tmp += '</form>';

    return tmp;
}

function str2xmlDOM(str) {
    var parser = new DOMParser();

    return parser.parseFromString(str, 'text/xml');
}

function ID2xmlDOM(id, fejtoMod) {
    var azonosito = 'rejtvenyID';
    if (fejtoMod != undefined) {
        azonosito = 'fejtesID';

        g_epitoMod = false;
    }

    var http = new XMLHttpRequest();
    http.open(
        'GET',
        'index.php?oldal=megnyitas&' + azonosito + '=' + id,
        false,
    );
    http.send();

    var tomb = http.responseText.split('||', 2);

    g_rejtvenyID = id;

    if (!g_epitoMod) {
        http = new XMLHttpRequest();
        http.open(
            'GET',
            'index.php?oldal=rejtveny&' + azonosito + '=' + id,
            false,
        );
        http.send();

        g_rejtvenyID = http.responseText;

        g_fejtesID = id;
    }

    g_ellenorizve = tomb[0] == 1 ? true : false;

    return str2xmlDOM(tomb[1]);
}

function getBeallitasokXML() {
    var param = '';
    if (!g_epitoMod) {
        param = '&fejtoMod=1';
    }

    var http = new XMLHttpRequest();

    http.open(
        'GET',
        'index.php?oldal=beallitasok&rejtvenyID=' + g_rejtvenyID + param,
        false,
    );
    http.send();

    return http.responseText;
}

function initRejtvenyFejto(kompID, fejtesID) {
    initRejtvenyKomponens(kompID, ID2xmlDOM(fejtesID, true));

    var http = new XMLHttpRequest();

    http.open('GET', 'index.php?oldal=fejtes&fejtesID=' + g_fejtesID, false);
    http.send();

    var xmlDOM = str2xmlDOM(http.responseText);

    var leiras = xmlDOM.getElementsByTagName('leírás');
    document.getElementById('rejtveny_leiras').innerHTML =
        leiras.item(0).textContent;

    var oszlopok = xmlDOM.getElementsByTagName('oszlop');

    var i;
    for (i = 0; i < oszlopok.length; i++) {
        var oszlop = document.getElementById('oszlop-' + i);

        oszlop.innerHTML = oszlopok.item(i).textContent;

        oszlop.onmousedown = onMouseDownDrag;
        oszlop.onmouseup = onMouseUpDrag;
        oszlop.ondblclick = onDblClickDrag;
    }

    setMaszk(g_aktualisObj);
}

function initRejtvenyEpito(kompID, rejtvenyID) {
    initRejtvenyKomponens(kompID, ID2xmlDOM(rejtvenyID));
}

function initRejtvenyKomponens(kompID, xmlDOM) {
    document.getElementsByTagName('body')[0].innerHTML +=
        '<div id="zarRejtveny" onmousedown="javascript:showMentes();"></div>';

    document.getElementsByTagName('body')[0].innerHTML +=
        '<div id="zarFixMenu" onmousedown="javascript:showMentes();"></div>';

    document.getElementsByTagName('body')[0].innerHTML +=
        '<div id="zarPopUp"></div>';

    document.getElementById(kompID).innerHTML = getRejtvenyKomponens();

    g_rejtveny = getRejtveny(xmlDOM);

    if (typeof g_rejtveny === 'object') {
        document.getElementById('rejtveny').innerHTML = g_rejtveny.toString();

        initSzinSema();

        initMenukSavok();

        addRejtettElemek();

        updateMeghatarozasok();

        eventRejtveny(0, g_mezoIrany);

        updateMegoldasSav();
    } else {
        document.getElementById('rejtveny_fejlec').innerHTML = 'XML hiba';
        document.getElementById('rejtveny').innerHTML = g_rejtveny;
    }
}

function reloadRejtveny(str, mezoID, mezoIrany) {
    g_rejtveny = getRejtveny(str2xmlDOM(str));

    document.getElementById('rejtveny').innerHTML = g_rejtveny.toString();

    addRejtettElemek();

    updateMeghatarozasok();
    eventRejtveny(mezoID, mezoIrany);
}

function getRejtveny(xmlDOM) {
    var index = 0;

    if (xmlDOM.documentElement.nodeName == 'hiba') {
        return xmlDOM.documentElement.textContent;
    } else {
        var rejtveny = new Rejtveny(xmlDOM.documentElement.nodeName);

        var adatlap = xmlDOM.getElementsByTagName('adatlap');
        rejtveny.setAdatlap(adatlap.item(0).textContent);

        var grafika = xmlDOM.getElementsByTagName('grafika');
        rejtveny.setGrafika(grafika.item(0));

        rejtveny.setSzinek(
            grafika.item(0).getAttribute('keretszín'),
            grafika.item(0).getAttribute('háttérszín'),
            grafika.item(0).getAttribute('betűszín'),
        );

        var megoldas = xmlDOM.getElementsByTagName('megoldás');
        if (megoldas.length > 0)
            rejtveny.setMegoldas(megoldas.item(0).textContent);

        var h = xmlDOM.getElementsByTagName('hálózat');

        var i, j, k;
        for (i = 0; i < h.length; i++) {
            var halozat = new Halozat(i);
            halozat.setminID(index);

            var s = h[i].getElementsByTagName('sor');
            halozat.setSor(s.length);

            for (j = 0; j < s.length; j++) {
                var m = s[j].getElementsByTagName('mező');
                for (k = 0; k < m.length; k++) {
                    var value = m[k].childNodes[0];
                    if (value != undefined) {
                        value = value.nodeValue;
                    } else {
                        value = '';
                    }

                    var mezo = new Mezo(
                        index,
                        value,
                        m[k].getAttribute('típus'),
                        m[k].getAttribute('vonal'),
                        m[k].getAttribute('gyengevonal'),
                        m[k].getAttribute('vmeghatározás'),
                        m[k].getAttribute('fmeghatározás'),
                        m[k].getAttribute('vhmeghatározás'),
                        m[k].getAttribute('fhmeghatározás'),
                        m[k].getAttribute('vkifejezés'),
                        m[k].getAttribute('fkifejezés'),
                        m[k].getAttribute('vhkifejezés'),
                        m[k].getAttribute('fhkifejezés'),
                        m[k].getAttribute('hídpozíció'),
                        m[k].getAttribute('ismert'),
                    );

                    if (k === 0) {
                        mezo.setSorTores();
                        mezo.setBorderLeft();
                    }
                    if (index === 0) {
                        mezo.setBorderLeft();
                        mezo.setBorderTop();
                    }
                    if (j === 0) {
                        mezo.setBorderTop();
                    }

                    halozat.push(mezo);
                    index++;
                }
            }
            halozat.setmaxID(index - 1);

            rejtveny.push(halozat);
        }
        rejtveny.setHossz(index);
        rejtveny.initKifejezesHibak();
        rejtveny.initBehuzas();
        rejtveny.initVonalak();

        return rejtveny;
    }
}

function initSzinSema() {
    var i;

    var szamosmezok = document.getElementsByClassName('számosmező');

    for (i = 0; i < szamosmezok.length; i++) {
        szamosmezok[i].style.backgroundColor = g_rejtveny.getKeretSzin();
        szamosmezok[i].style.borderColor = g_rejtveny.getKeretSzin();
        szamosmezok[i].style.color = g_rejtveny.getBetuSzin();
    }

    var szinesmezok = document.getElementsByClassName('színes');

    for (i = 0; i < szinesmezok.length; i++) {
        szinesmezok[i].style.backgroundColor = g_rejtveny.getHatterSzin();
    }
}

function initMenukSavok() {
    g_elozmeny = new Elozmeny(20);
    g_elozmeny.mentes('Rejtvény betöltése');

    document.getElementById('rejtveny_elozmenysav').innerHTML = g_elozmeny;

    g_beallitas = new Beallitas(g_rejtveny.getTipus(), getBeallitasokXML());

    g_foMenu = new Menu();

    g_megoldasMenu = new MegoldasMenu(g_rejtveny.getMegoldas());

    document.getElementById('rejtveny_megoldassav').innerHTML = g_megoldasMenu;

    g_meghatarozasMenu = new MeghMenu();
}

function addRejtettElemek() {
    document.getElementById('rejtveny').innerHTML +=
        '<div id="aktivmezo">&nbsp;</div>';
    document.getElementById('rejtveny').innerHTML +=
        '<div id="aktivtomb">&nbsp;</div>';
    document.getElementById('rejtveny').innerHTML +=
        '<div id="aktivszinkronmezo">&nbsp;</div>';
    document.getElementById('rejtveny').innerHTML +=
        '<div id="rejtveny_popupmenu">&nbsp;</div>';

    document.getElementById('aktivmezo').contentEditable = false;
    document.getElementById('aktivtomb').contentEditable = false;
    document.getElementById('aktivszinkronmezo').contentEditable = false;
    document.getElementById('rejtveny_popupmenu').contentEditable = false;
}

function eventRejtveny(mezoID, mezoIrany) {
    var halozatok = document.getElementById('rejtveny').childNodes;
    var halozatID;
    var mezok;

    var i, j;

    for (j = 0; j < halozatok.length; j++) {
        halozatID = halozatok[j].getAttribute('id');

        if (halozatID.search('halozat') !== -1) {
            mezok = document.getElementById(halozatID).childNodes[2].childNodes;

            for (i = 0; i < mezok.length; i++) {
                mezok[i].contentEditable = true;

                mezok[i].onmousedown = onMouseDownMezo;
                mezok[i].ondblclick = onDblClickMezo;
                mezok[i].onkeypress = onKeyPressMezo;
                mezok[i].onkeydown = onKeyDownMezo;
                mezok[i].onkeyup = onKeyUpMezo;
            }
        }
    }

    setAktualisObj(document.getElementById('mezo-' + mezoID));
    g_mezoIrany = mezoIrany;
    switchObj(g_aktualisObj);
}

function onMouseDownMezo(e) {
    var event = e || window.event;
    var node = event.srcElement || event.target;

    switchObj(node);
}

function onDblClickMezo(e) {
    var event = e || window.event;
    var node = event.srcElement || event.target;

    var tmp = document.getElementById('rejtvenyKomponens');

    var x = event.pageX - tmp.offsetLeft;
    var y = event.pageY - tmp.offsetTop - 84;

    if (
        node.offsetLeft + node.offsetWidth - 10 < x &&
        x < node.offsetLeft + node.offsetWidth
    ) {
        setFvonal(node);
    } else if (
        node.offsetTop + node.offsetHeight - 10 < y &&
        y < node.offsetTop + node.offsetHeight
    ) {
        setVvonal(node);
    }
}

function onKeyPressMezo(e) {
    var event = e || window.event;
    event.preventDefault();

    if (!event.ctrlKey) {
        var charCode = event.charCode || event.which;

        var chr = String.fromCharCode(charCode);

        var index = objDOM2ID(this);

        var regex = new RegExp(g_joKarakterekMezo, 'g');

        if (regex.test(chr) && !g_rejtveny.getMezo(index).isIsmert()) {
            if (!(!g_epitoMod && g_rejtveny.getMezo(index).isAbra())) {
                if (g_szinkronSzerkesztes) {
                    var index2 = g_rejtveny.getNextMezo(index).getMezoID();
                    var mezo = document.getElementById(index2);

                    if (g_rejtveny.getMezo(objDOM2ID(mezo)).isFekete()) {
                        setFekete(mezo);
                    } else if (g_rejtveny.getMezo(objDOM2ID(mezo)).isAbra()) {
                        setAbra(mezo);
                    }
                }

                if (
                    g_rejtveny.getMezo(index).isAbra() ||
                    g_rejtveny.getMezo(index).isFekete() ||
                    g_rejtveny.getMezo(index).isUres()
                ) {
                    setUresMezo(this);
                }

                var tmp = chr.toUpperCase();
                this.innerHTML = tmp;
                g_rejtveny.getMezo(index).setErtek(tmp);

                switchObj(this, true);

                if (g_mezoIrany) {
                    index = g_rejtveny.getHalozat(index).kovetkezoMezo(index);
                } else {
                    index = g_rejtveny.getHalozat(index).alsoMezo(index);
                }
                var mezo = document.getElementById('mezo-' + index);
                switchObj(mezo);

                g_elozmeny.mentes('Beírás ' + tmp);
                updateElozmenySav();

                updateMenusor();
            }
        }
    }
}

function onKeyDownMezo(e) {
    var event = e || window.event;

    // Ctrl+S
    if (event.ctrlKey && event.keyCode == 83) {
        mentes();
    }

    // Ctrl+Z
    if (event.ctrlKey && event.keyCode == 90) {
        elozmenyVisszavonas();
    }

    // Ctrl+Y
    if (event.ctrlKey && event.keyCode == 89) {
        elozmenyIsmet();
    }

    // Ctrl+M
    if (event.ctrlKey && event.keyCode == 77) {
        setSzines(this);
    }

    // Ctrl+H
    if (event.ctrlKey && event.keyCode == 72) {
        setHid(this);
    }

    // Ctrl+A
    if (event.ctrlKey && event.keyCode == 65) {
        setAbra(this);
    }

    // Ctrl+N
    if (event.ctrlKey && event.keyCode == 78) {
        setFekete(this);
    }

    // Ctrl+V
    if (event.ctrlKey && event.keyCode == 86) {
        setVvonal(this);
    }

    // Ctrl+F
    if (event.ctrlKey && event.keyCode == 70) {
        setFvonal(this);
    }

    // Ctrl+E
    if (event.ctrlKey && event.keyCode == 69) {
        szinkronizal(this);
    }

    // Ctrl+I
    if (event.ctrlKey && event.keyCode == 73) {
        setIsmert(this);
    }
}

function onKeyUpMezo(e) {
    var event = e || window.event;

    var index = objDOM2ID(this);
    var mezo;

    switch (event.keyCode) {
        case 37: // left
            index = g_rejtveny.getHalozat(index).elozoMezo(index);
            mezo = document.getElementById('mezo-' + index);
            switchObj(mezo);
            break;
        case 38: // up
            index = g_rejtveny.getHalozat(index).felsoMezo(index);
            mezo = document.getElementById('mezo-' + index);
            switchObj(mezo);
            break;
        case 39: // right
            index = g_rejtveny.getHalozat(index).kovetkezoMezo(index);
            mezo = document.getElementById('mezo-' + index);
            switchObj(mezo);
            break;
        case 40: // down
            index = g_rejtveny.getHalozat(index).alsoMezo(index);
            mezo = document.getElementById('mezo-' + index);
            switchObj(mezo);
            break;
        case 9: // tab
            index = g_rejtveny.getNextMezo(index).getMezoID();
            mezo = document.getElementById(index);
            switchObj(mezo);
            break;
        case 32: // space
            changeMezoIrany(this);
            break;
        case 13: // enter
            var aktivmezo = document.getElementById('aktivmezo');
            var aktivtomb = document.getElementById('aktivtomb');
            var aktivszinkronmezo =
                document.getElementById('aktivszinkronmezo');

            if (g_mezoKiemeles) {
                aktivmezo.style.display = 'none';
                aktivtomb.style.display = 'none';
                aktivszinkronmezo.style.display = 'none';

                if (!g_meghatarozasJeloles) {
                    resetObj(this, true);
                } else {
                    resetObj(this);
                }
            } else {
                switchObj(this);
            }
            break;
        case 8: // backspace
            setUresErtek(this);
            switchObj(this, true);

            if (g_mezoIrany) {
                index = g_rejtveny.getHalozat(index).elozoMezo(index);
            } else {
                index = g_rejtveny.getHalozat(index).felsoMezo(index);
            }
            mezo = document.getElementById('mezo-' + index);
            switchObj(mezo);

            g_elozmeny.mentes('Karakter visszatörlés');
            updateElozmenySav();

            break;
        case 46: // delete
            setUresMezo(this);
            switchObj(this, true);

            g_elozmeny.mentes('Mező törlés');
            updateElozmenySav();
            break;
        case 45: // insert
            if (document.getElementById('textareaMeghatarozas') != undefined) {
                document.getElementById('textareaMeghatarozas').focus();
            }
            break;
    }
}

function objDOM2obj(objDOM) {
    return g_rejtveny.getMezo(objDOM2ID(objDOM));
}

function objDOM2ID(objDOM) {
    return parseInt(objDOM.getAttribute('id').replace('mezo-', ''), 10);
}

function clrKiemeles(tomb) {
    var mezo;
    var i;

    for (i = 0; i < tomb.length; i++) {
        mezo = document.getElementById('mezo-' + tomb[i]);
        mezo.style.opacity = '';
        if (mezo.style.backgroundColor == 'rgb(240, 240, 240)') {
            mezo.style.backgroundColor = '';
        }
        mezo.style.color = '';

        if (!g_meghatarozasJeloles) {
            mezo.style.backgroundImage = '';

            if (g_rejtveny.getMezo(tomb[i]).hasKifejezesHiba()) {
                mezo.style.backgroundImage = getBackgroundImage(
                    tomb[i],
                    null,
                    true,
                );
            }
        } else {
            mezo.style.backgroundImage = getBackgroundImage(tomb[i]);
        }
    }
}

function resetObj(objDOM, clear) {
    var mezo;
    var i;

    if (clear) {
        for (i = 0; i < g_rejtveny.getHossz(); i++) {
            mezo = document.getElementById('mezo-' + i);
            mezo.style.opacity = '';
            if (mezo.style.backgroundColor == 'rgb(240, 240, 240)') {
                mezo.style.backgroundColor = '';
            }

            mezo.style.backgroundImage = '';
            mezo.style.color = '';

            if (g_rejtveny.getMezo(i).hasKifejezesHiba()) {
                mezo.style.backgroundImage = getBackgroundImage(i, null, true);
            }
        }
    } else {
        clrKiemeles(g_aktualisTomb);
        clrKiemeles(g_aktualisInvTomb);
    }

    //objDOM.style.fontWeight = "";
    if (!g_meghatarozasJeloles) {
        objDOM.style.backgroundImage = '';

        if (g_rejtveny.getMezo(objDOM2ID(objDOM)).hasKifejezesHiba()) {
            objDOM.style.backgroundImage = getBackgroundImage(
                objDOM2ID(objDOM),
                null,
                true,
            );
        }
    }

    if (g_rejtveny.getMezo(objDOM2ID(objDOM)).isAbra() && g_maszkJeloles) {
        objDOM.style.backgroundImage = g_rejtveny.getMaszkImage(
            objDOM2ID(objDOM),
        );
    }

    objDOM.style.opacity = '';
    //objDOM.style.backgroundColor = "";
    objDOM.style.color = '';

    g_mezoKiemeles = false;
}

function setAktualisObj(objDOM) {
    g_aktualisObj = objDOM;
    g_aktualisMezoID = objDOM2ID(objDOM);
}

function initDirTomb(mezoID, mezoIrany, nincsEllenorzes) {
    var tomb = [];

    if (mezoIrany) {
        tomb = g_rejtveny
            .getHalozat(mezoID)
            .vmezoSelect(mezoID, nincsEllenorzes);
    } else {
        tomb = g_rejtveny
            .getHalozat(mezoID)
            .fmezoSelect(mezoID, nincsEllenorzes);
    }
    return tomb;
}

function selectObj(objDOM) {
    resetObj(g_aktualisObj);

    setAktualisObj(objDOM);

    g_aktualisTomb = initDirTomb(g_aktualisMezoID, g_mezoIrany);
    g_aktualisInvTomb = initDirTomb(g_aktualisMezoID, !g_mezoIrany);

    var i;

    var mezo;
    for (i = 0; i < g_aktualisTomb.length; i++) {
        mezo = document.getElementById('mezo-' + g_aktualisTomb[i]);
        mezo.style.opacity = '0.4';

        if (
            !g_rejtveny.getMezo(g_aktualisTomb[i]).isSzines() &&
            !g_rejtveny.getMezo(g_aktualisTomb[i]).isHid()
        ) {
            mezo.style.backgroundColor = 'rgb(240, 240, 240)';
            mezo.style.opacity = '0.7';
        }
        mezo.style.color = 'rgb(0, 0, 0)';
    }

    //objDOM.style.fontWeight = "bold";
    objDOM.style.opacity = '0.7';
    objDOM.style.color = 'rgb(0, 0, 0)';

    g_mezoKiemeles = true;

    updateMenusor();

    objDOM.focus();
}

function switchObj(objDOM, meghatarozasFigyeles, nincsEllenorzes) {
    var aktObjektum = g_rejtveny.getMezo(objDOM2ID(objDOM));

    if (!(!g_epitoMod && aktObjektum.isAbra())) {
        selectObj(objDOM);

        var aktivmezo = document.getElementById('aktivmezo');

        aktivmezo.style.left = objDOM.offsetLeft + 'px';
        aktivmezo.style.top = objDOM.offsetTop + 'px';
        aktivmezo.style.display = 'block';

        if (g_szinkronSzerkesztes) {
            var szikronMezoID = g_rejtveny
                .getNextMezo(objDOM2ID(objDOM))
                .getMezoID();
            var szinkronMezoDOM = document.getElementById(szikronMezoID);

            var aktivszinkronmezo =
                document.getElementById('aktivszinkronmezo');

            aktivszinkronmezo.style.left = szinkronMezoDOM.offsetLeft + 'px';
            aktivszinkronmezo.style.top = szinkronMezoDOM.offsetTop + 'px';

            aktivszinkronmezo.style.display = 'block';
        }

        var aktivtomb = document.getElementById('aktivtomb');

        var tomb = getTeljesTomb(g_aktualisTomb);

        aktivtomb.style.left =
            document.getElementById('mezo-' + tomb[0]).offsetLeft + 'px';
        aktivtomb.style.top =
            document.getElementById('mezo-' + tomb[0]).offsetTop + 'px';

        var hossz = tomb.length * 27 + (tomb.length - 1) * 1;

        if (g_mezoIrany) {
            aktivtomb.style.width = hossz + 'px';
            aktivtomb.style.height = '27px';
        } else {
            aktivtomb.style.width = '27px';
            aktivtomb.style.height = hossz + 'px';
        }
        aktivtomb.style.display = 'block';

        var aktualisTomb2 = g_aktualisTomb.slice();
        var aktualisInvTomb2 = g_aktualisInvTomb.slice();
        if (nincsEllenorzes) {
            aktualisTomb2 = initDirTomb(
                g_aktualisMezoID,
                g_mezoIrany,
                nincsEllenorzes,
            );
            aktualisInvTomb2 = initDirTomb(
                g_aktualisMezoID,
                !g_mezoIrany,
                nincsEllenorzes,
            );
        }

        g_meghatarozasMenu.setMezoDir(!g_mezoIrany);
        g_meghatarozasMenu.setMezoID(
            g_rejtveny,
            getElsoMezoID(aktualisInvTomb2),
            g_aktualisMezoID,
        );
        if (meghatarozasFigyeles) {
            g_meghatarozasMenu.enableHiba(
                getKifejezes(getTeljesTomb(aktualisInvTomb2)),
            );
        }
        if (!g_meghatarozasJeloles) {
            updateBackgroundImage(
                getTeljesTomb(aktualisInvTomb2),
                !g_mezoIrany,
            );
        } else {
            updateBackgroundImage(getTeljesTomb(aktualisInvTomb2));
        }

        g_meghatarozasMenu.setMezoDir(g_mezoIrany);
        g_meghatarozasMenu.setMezoID(
            g_rejtveny,
            getElsoMezoID(aktualisTomb2),
            g_aktualisMezoID,
        );
        if (meghatarozasFigyeles) {
            g_meghatarozasMenu.enableHiba(
                getKifejezes(getTeljesTomb(aktualisTomb2)),
            );
        }
        if (!g_meghatarozasJeloles) {
            updateBackgroundImage(getTeljesTomb(aktualisTomb2), g_mezoIrany);
        } else {
            updateBackgroundImage(getTeljesTomb(aktualisTomb2));
        }

        updateMeghatarozasSav();

        if (meghatarozasFigyeles) {
            updateMeghatarozasok();
        }

        objDOM.style.backgroundImage = getBackgroundImage(g_aktualisMezoID);
    }
}

function getMeghatarozas() {
    var mezoID = getElsoMezoID(g_aktualisTomb);
    var ertek = '';

    if (
        g_mezoIrany &&
        g_rejtveny.getHalozat(g_aktualisMezoID).isVcsatorna(g_aktualisMezoID)
    ) {
        ertek = g_rejtveny.getMezo(mezoID).getVHmeghatarozas();
    } else if (
        g_rejtveny.getHalozat(g_aktualisMezoID).isFcsatorna(g_aktualisMezoID)
    ) {
        ertek = g_rejtveny.getMezo(mezoID).getFHmeghatarozas();
    } else {
        if (g_mezoIrany) {
            ertek = g_rejtveny.getMezo(mezoID).getVmeghatarozas();
        } else {
            ertek = g_rejtveny.getMezo(mezoID).getFmeghatarozas();
        }
    }
    if (ertek != null) {
        document.getElementById('textareaMeghatarozas').value = ertek;
    }
}

function setMeghatarozas(ertek) {
    var mezoID = getElsoMezoID(g_aktualisTomb);
    var mezo = g_rejtveny.getMezo(mezoID);

    if (
        g_mezoIrany &&
        g_rejtveny.getHalozat(g_aktualisMezoID).isVcsatorna(g_aktualisMezoID)
    ) {
        mezo.setVHmeghatarozas(ertek.extraTrim());
        if (mezo.hasVHmeghatarozas()) {
            mezo.setVHkifejezes(getKifejezes(getTeljesTomb(g_aktualisTomb)));
            mezo.setHidPozicio(g_aktualisMezoID);
        }
    } else if (
        g_rejtveny.getHalozat(g_aktualisMezoID).isFcsatorna(g_aktualisMezoID)
    ) {
        mezo.setFHmeghatarozas(ertek.extraTrim());
        if (mezo.hasFHmeghatarozas()) {
            mezo.setFHkifejezes(getKifejezes(getTeljesTomb(g_aktualisTomb)));
            mezo.setHidPozicio(g_aktualisMezoID);
        }
    } else {
        if (g_mezoIrany) {
            mezo.setVmeghatarozas(ertek.extraTrim());
            if (mezo.hasVmeghatarozas()) {
                mezo.setVkifejezes(getKifejezes(getTeljesTomb(g_aktualisTomb)));
            }
        } else {
            mezo.setFmeghatarozas(ertek.extraTrim());
            if (mezo.hasFmeghatarozas()) {
                mezo.setFkifejezes(getKifejezes(getTeljesTomb(g_aktualisTomb)));
            }
        }
    }

    document.getElementById('mezo-' + mezoID).style.backgroundImage =
        getBackgroundImage(mezoID, g_mezoIrany);

    g_meghatarozasMenu.disableHiba();

    updateMeghatarozasSav();

    updateBackgroundImage(getTeljesTomb(g_aktualisTomb), g_mezoIrany);

    updateMeghatarozasok();

    g_elozmeny.mentes('Meghatározás megadása');
    updateElozmenySav();

    g_aktualisObj.focus();
}

function setMegoldas(ertek) {
    g_rejtveny.setMegoldas(ertek);

    g_megoldasMenu.setMegoldas(ertek);

    updateMegoldasSav();

    g_aktualisObj.focus();
}

function getTeljesTomb(tomb) {
    var tmp = tomb.slice();

    tmp.push(g_aktualisMezoID);
    tmp.sort(rendezes);

    return tmp;
}

function getKifejezes(tomb) {
    var str = '';
    var i;

    for (i = 0; i < tomb.length; i++) {
        var tmp = g_rejtveny.getMezo(tomb[i]).getErtek();

        var regex = new RegExp(g_joKarakterekMezo, 'g');

        if (regex.test(tmp) && tmp != null) {
            str += tmp;
        } else {
            str += '_';
        }
    }
    return str;
}

function getElsoMezoID(tomb) {
    var tmp = getTeljesTomb(tomb);

    return tmp[0];
}

function showInformacio(objDOM) {
    var tmp = '<form>';
    tmp += '<fieldset id="informacio">Töltés...</fieldset>';
    tmp +=
        '<fieldset><input type="button" onclick="javascript:hidePopup();" value="OK"></fieldset>';
    tmp += '</form>';

    showPopup(tmp);

    var http = new XMLHttpRequest();

    http.open(
        'GET',
        'index.php?oldal=informacio&rejtvenyID=' + g_rejtvenyID,
        true,
    );

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            document.getElementById('informacio').innerHTML =
                http.responseText +
                '<br><br>Üzemmód: ' +
                (g_epitoMod ? 'építő' : 'fejtő');

            updatePopup();
        }
    };

    http.send();
}

function showKereses() {
    var tomb = getTeljesTomb(g_aktualisTomb);
    var kifejezes = getKifejezes(tomb);

    var tmp = '<form>';
    tmp += '<fieldset id="keresesSelect">Keresés...</fieldset>';
    tmp += '</form>';

    showPopup(tmp);

    keresKifejezes(
        kifejezes,
        kifejezes,
        document.getElementById('keresesSelect'),
    );
}

function keresKifejezes(minta, kifejezes, objDOM) {
    var http = new XMLHttpRequest();

    var params = 'minta=' + minta + '&kifejezes=' + kifejezes;

    http.open('POST', 'index.php?oldal=kereses', true);

    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.setRequestHeader('Content-length', params.length);
    http.setRequestHeader('Connection', 'close');

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            objDOM.innerHTML = http.responseText;
            if (document.getElementById('keresesGomb') == undefined) {
                objDOM.parentNode.innerHTML +=
                    '</fieldset><fieldset id="keresesGomb"><input type="button" onclick="javascript:keresesBeszuras();" value="Beszúrás"><input type="reset" onclick="javascript:hidePopup();" value="Mégse">';
            }
            updatePopup();
        }
    };

    http.send(params);
}

function keresesBeszuras() {
    var i = document.getElementById('selectKifejezesek').selectedIndex;
    var j = document.getElementById('selectMeghatarozasok').selectedIndex;

    setKifejezes(document.getElementById('selectKifejezesek').options[i].value);

    setMeghatarozas(
        document.getElementById('selectMeghatarozasok').options[j].value,
    );

    hidePopup();

    g_aktualisObj.focus();
}

function showMentes() {
    var tmp = '<form>';
    tmp += '<fieldset>A nem mentett változtatások el fognak veszni!</fieldset>';
    tmp += '<fieldset>';
    tmp +=
        '<input type="button" onclick="javascript:mentes();" value="Mentés">';
    tmp +=
        '<input type="reset" onclick="javascript:mentesMegse();" value="Mégse">';
    tmp += '</fieldset>';
    tmp += '</form>';

    showPopup(tmp);
}

function showPopup(str) {
    document.getElementById('rejtveny_popupmenu').innerHTML = str;

    updatePopup();

    document.getElementById('zarFixMenu').style.display = 'none';
    document.getElementById('zarRejtveny').style.display = 'none';
    document.getElementById('zarPopUp').style.display = 'block';
    document.getElementById('rejtvenyKomponens').style.position = 'static';
}

function updatePopup() {
    var popup = document.getElementById('rejtveny_popupmenu');

    popup.style.display = 'block';
    popup.style.left =
        Math.ceil(
            document.getElementById('rejtveny').offsetWidth / 2 -
                popup.offsetWidth / 2,
        ) + 'px';
    popup.style.top =
        Math.ceil(
            document.getElementById('rejtveny').offsetHeight / 2 -
                popup.offsetHeight / 2,
        ) + 'px';
}

function hidePopup() {
    document.getElementById('rejtveny_popupmenu').style.display = 'none';

    document.getElementById('zarFixMenu').style.display = 'block';
    document.getElementById('zarRejtveny').style.display = 'block';
    document.getElementById('zarPopUp').style.display = 'none';
    document.getElementById('rejtvenyKomponens').style.position = 'relative';

    g_aktualisObj.focus();
}

function onKeyUpMegoldas(e) {
    var event = e || window.event;
    //event.preventDefault();

    switch (event.keyCode) {
        case 13: // enter
            setMegoldas(this.value);
            break;
    }
    //return false;
}

function onKeyUpMeghatarozas(e) {
    var event = e || window.event;
    //event.preventDefault();

    switch (event.keyCode) {
        case 13: // enter
            setMeghatarozas(this.value);

            break;
    }
    //return false;
}

function setUresMezo(objDOM) {
    objDOM.innerHTML = '';
    objDOM.className = objDOM
        .getAttribute('class')
        .replace(classReplace(), '')
        .replace(/(vvonal|fvonal)/g, '');

    g_rejtveny.getMezo(objDOM2ID(objDOM)).setUresMezo();

    updateMenusor();

    objDOM.focus();
}

function setUresErtek(objDOM) {
    objDOM.innerHTML = '';
    objDOM.className = objDOM.getAttribute('class').replace(classReplace(), '');

    g_rejtveny.getMezo(objDOM2ID(objDOM)).setUresErtek();

    updateMenusor();

    objDOM.focus();
}

function setFekete(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (
        g_beallitas.allowFekete() &&
        !aktObjektum.isIsmert() &&
        !(aktObjektum.isVvonal() || aktObjektum.isFvonal())
    ) {
        aktiv = '';
        if (aktObjektum.isFekete()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isFekete() ==
                g_rejtveny.getMezo(index).isFekete()
            ) {
                classAttr(mezo, 'fekete');
                mezo.className = mezo
                    .getAttribute('class')
                    .replace(classReplace('fekete'), '');
                mezo.innerHTML = '';

                g_rejtveny.getMezo(objDOM2ID(mezo)).setFekete();

                switchObj(mezo, true, true);
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isFekete()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'fekete');
        objDOM.className = objDOM
            .getAttribute('class')
            .replace(classReplace('fekete'), '');
        objDOM.innerHTML = '';

        g_rejtveny.getMezo(index).setFekete();

        switchObj(objDOM, true, true);

        g_elozmeny.mentes('Fekete négyzet' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}

function setSzines(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (g_beallitas.allowMegfejtes()) {
        aktiv = '';
        if (aktObjektum.isSzines()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isSzines() ==
                g_rejtveny.getMezo(index).isSzines()
            ) {
                classAttr(mezo, 'színes');
                mezo.className = mezo
                    .getAttribute('class')
                    .replace(classReplace('színes'), '');

                g_rejtveny.getMezo(objDOM2ID(mezo)).setSzines();
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isSzines()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'színes');
        objDOM.className = objDOM
            .getAttribute('class')
            .replace(classReplace('színes'), '');

        g_rejtveny.getMezo(index).setSzines();

        g_elozmeny.mentes('Megfejtés betűje' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}
function setHid(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (g_beallitas.allowHid()) {
        aktiv = '';
        if (aktObjektum.isHid()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isHid() ==
                g_rejtveny.getMezo(index).isHid()
            ) {
                classAttr(mezo, 'híd');
                mezo.className = mezo
                    .getAttribute('class')
                    .replace(classReplace('híd'), '');

                g_rejtveny.getMezo(objDOM2ID(mezo)).setHid();
                switchObj(mezo, true, true);
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isHid()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'híd');
        objDOM.className = objDOM
            .getAttribute('class')
            .replace(classReplace('híd'), '');

        g_rejtveny.getMezo(index).setHid();
        switchObj(objDOM, true, true);

        g_elozmeny.mentes('híd betűje' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}
function setUres(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (g_beallitas.allowUres() && !aktObjektum.isIsmert()) {
        aktiv = '';
        if (aktObjektum.isUres()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isUres() ==
                g_rejtveny.getMezo(index).isUres()
            ) {
                classAttr(mezo, 'üres');
                mezo.className = mezo
                    .getAttribute('class')
                    .replace(classReplace('ures'), '');
                mezo.innerHTML = '';

                g_rejtveny.getMezo(objDOM2ID(mezo)).setUres();
                switchObj(mezo, true, true);
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isUres()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'üres');
        objDOM.className = objDOM
            .getAttribute('class')
            .replace(classReplace('üres'), '');
        objDOM.innerHTML = '';

        g_rejtveny.getMezo(index).setUres();
        switchObj(objDOM, true, true);

        g_elozmeny.mentes('üres' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}

function setAbra(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (
        g_beallitas.allowAbra() &&
        !aktObjektum.isIsmert() &&
        !(aktObjektum.isVvonal() || aktObjektum.isFvonal())
    ) {
        aktiv = '';
        if (aktObjektum.isAbra()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isAbra() ==
                g_rejtveny.getMezo(index).isAbra()
            ) {
                classAttr(mezo, 'ábra');
                mezo.className = mezo
                    .getAttribute('class')
                    .replace(classReplace('ábra'), '');
                mezo.innerHTML = '';

                g_rejtveny.getMezo(objDOM2ID(mezo)).setAbra();
                switchObj(mezo, true, true);
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isAbra()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'ábra');
        objDOM.className = objDOM
            .getAttribute('class')
            .replace(classReplace('ábra'), '');
        objDOM.innerHTML = '';

        g_rejtveny.getMezo(index).setAbra();
        switchObj(objDOM, true, true);

        g_elozmeny.mentes('ábra' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}

function getBackgroundImage(mezoID, vizszintes, onlywarning) {
    return g_rejtveny
        .getMezo(mezoID)
        .getBackgroundImage(vizszintes, onlywarning);
}

function updateBackgroundImage(tomb, vizszintes, onlywarning) {
    document.getElementById('mezo-' + tomb[0]).style.backgroundImage =
        getBackgroundImage(tomb[0], vizszintes, onlywarning);
}

function setVvonal(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (aktObjektum.engedelyezVVonal() && !aktObjektum.isFekete()) {
        aktiv = '';
        if (aktObjektum.isVvonal()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isVvonal() ==
                g_rejtveny.getMezo(index).isVvonal()
            ) {
                classAttr(mezo, 'vvonal');
                g_rejtveny.getMezo(objDOM2ID(mezo)).setVvonal();
                switchObj(mezo, true);
            }
        }

        var str = '';
        if (g_rejtveny.getMezo(index).isVvonal()) {
            str = ' visszavonás';
        }

        classAttr(objDOM, 'vvonal');
        g_rejtveny.getMezo(index).setVvonal();
        switchObj(objDOM, true);

        g_elozmeny.mentes('Vízszintes vonal' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}
function setFvonal(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    if (aktObjektum.engedelyezFVonal() && !aktObjektum.isFekete()) {
        aktiv = '';
        if (aktObjektum.isFvonal()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        var str = '';
        if (g_rejtveny.getMezo(index).isFvonal()) {
            str = ' visszavonás';
        }

        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getMezoID();
            var mezo = document.getElementById(index2);

            if (
                g_rejtveny.getMezo(objDOM2ID(mezo)).isFvonal() ==
                g_rejtveny.getMezo(index).isFvonal()
            ) {
                classAttr(mezo, 'fvonal');
                g_rejtveny.getMezo(objDOM2ID(mezo)).setFvonal();
                switchObj(mezo, true);
            }
        }

        classAttr(objDOM, 'fvonal');
        g_rejtveny.getMezo(index).setFvonal();
        switchObj(objDOM, true);

        g_elozmeny.mentes('Függőleges vonal' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}

function setIsmert(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    var index = objDOM2ID(objDOM);
    var aktObjektum = g_rejtveny.getMezo(index);

    var regex = new RegExp(g_joKarakterekMezo, 'g');

    if (regex.test(aktObjektum.getErtek()) && g_epitoMod) {
        aktiv = '';
        if (aktObjektum.isIsmert()) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
        if (aktObjektum.isIsmert()) {
            aktiv = ' class="inaktiv aktiv"';
        }
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        var mezo = document.getElementById('mezo-' + index);

        var str = '';
        if (g_rejtveny.getMezo(index).isIsmert()) {
            str = ' visszavonás';
            mezo.style.fontWeight = '';
        } else {
            mezo.style.fontWeight = 'bold';
        }

        if (g_szinkronSzerkesztes) {
            var index2 = g_rejtveny.getNextMezo(index).getID();
            var mezo2 = document.getElementById('mezo-' + index2);

            if (
                g_rejtveny.getMezo(index2).isIsmert() ==
                g_rejtveny.getMezo(index).isIsmert()
            ) {
                if (g_rejtveny.getMezo(index2).isIsmert()) {
                    mezo2.style.fontWeight = '';
                } else {
                    mezo2.style.fontWeight = 'bold';
                }

                g_rejtveny.getMezo(index2).setIsmert();
            }
        }

        g_rejtveny.getMezo(index).setIsmert();

        g_elozmeny.mentes('Ismert mező' + str);
        updateElozmenySav();

        updateMenusor();

        objDOM.focus();
    }
}

function classReplace(tipus) {
    var tipusok = ['színes', 'híd', 'fekete', 'ábra', 'üres'];

    var str = '';
    var i = 0;

    for (i = 0; i < tipusok.length; i++) {
        if (tipusok[i] != tipus) {
            str += str != '' ? '|' + tipusok[i] : tipusok[i];
        }
    }
    return new RegExp('(' + str + ')', 'g');
}

function classAttr(objDOM, tipus) {
    if (objDOM.getAttribute('class') === null) {
        objDOM.setAttribute('class', tipus);
    } else if (objDOM.getAttribute('class').search(tipus) === -1) {
        objDOM.className += ' ' + tipus;
    } else {
        objDOM.className = objDOM.getAttribute('class').replace(tipus, '');
    }
    objDOM.className = objDOM.className.rtrim();
}

function jelolesek(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    if (g_epitoMod) {
        aktiv = '';
        if (g_meghatarozasJeloles) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        var i;

        if (g_meghatarozasJeloles) {
            for (i = 0; i < g_rejtveny.getHossz(); i++) {
                document.getElementById('mezo-' + i).style.backgroundImage = '';
                if (g_rejtveny.getMezo(i).hasKifejezesHiba()) {
                    document.getElementById('mezo-' + i).style.backgroundImage =
                        getBackgroundImage(i, null, true);
                }
            }
            updateBackgroundImage(getTeljesTomb(g_aktualisTomb), g_mezoIrany);
            updateBackgroundImage(
                getTeljesTomb(g_aktualisInvTomb),
                !g_mezoIrany,
            );
        } else {
            for (i = 0; i < g_rejtveny.getHossz(); i++) {
                document.getElementById('mezo-' + i).style.backgroundImage =
                    getBackgroundImage(i);
            }
        }
        g_meghatarozasJeloles = !g_meghatarozasJeloles;

        g_maszkJeloles = false;

        updateMenusor();

        objDOM.focus();
    }
}

function ismertJelolesek(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    if (g_ismertJeloles) {
        aktiv = ' class="aktiv"';
        lefuthat = true;
    } else {
        lefuthat = true;
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        var i;

        for (i = 0; i < g_rejtveny.getHossz(); i++) {
            var mezo = g_rejtveny.getMezo(i);

            document.getElementById('mezo-' + i).innerHTML = '';

            var regex = new RegExp(g_joKarakterekMezo, 'g');

            if (
                mezo.isIsmert() ||
                (g_ismertJeloles && regex.test(mezo.getErtek()))
            ) {
                document.getElementById('mezo-' + i).innerHTML =
                    mezo.getErtek();
            }
        }

        g_ismertJeloles = !g_ismertJeloles;

        updateMenusor();

        objDOM.focus();
    }
}

function changeMezoIrany(objDOM, kellClass) {
    var aktiv = '';

    if (!g_mezoIrany) {
        aktiv = ' class="aktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    g_mezoIrany = !g_mezoIrany;

    switchObj(objDOM);

    objDOM.focus();
}

function setMaszk(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    if (g_beallitas.allowAbra() && g_rejtveny.hasGrafika()) {
        if (g_maszkJeloles) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
        if (g_maszkJeloles && !g_epitoMod && g_rejtveny.hasGrafika()) {
            aktiv = ' class="aktiv inaktiv"';
        }
        if (!g_maszkJeloles && !g_epitoMod && g_rejtveny.hasGrafika()) {
            lefuthat = true;
        }
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        var i;

        var mezo;

        var tomb = g_rejtveny.getGrafika();

        if (g_maszkJeloles) {
            for (i = 0; i < tomb.length; i++) {
                var id = tomb[i].getHelye();

                if (g_rejtveny.getMezo(id).isAbra()) {
                    mezo = document.getElementById('mezo-' + id);

                    mezo.style.backgroundImage = getBackgroundImage(id);

                    //console.log(mezo.clientWidth);
                    if (mezo.clientWidth == 28) {
                        mezo.style.borderRight = '1px solid rgb(213, 213, 213)';
                        mezo.style.width = '21px';
                    }
                    //console.log(mezo.clientHeight);
                    if (mezo.clientHeight == 28) {
                        mezo.style.borderBottom =
                            '1px solid rgb(213, 213, 213)';
                        mezo.style.height = '19px';
                    }
                }
            }
        } else {
            for (i = 0; i < tomb.length; i++) {
                var id = tomb[i].getHelye();

                if (g_rejtveny.getMezo(id).isAbra()) {
                    mezo = document.getElementById('mezo-' + id);

                    mezo.style.backgroundImage = tomb[i].getBackgroundImage();

                    if (mezo.clientWidth == 27) {
                        mezo.style.borderRight = '0px';
                        mezo.style.width = '22px';
                    }

                    if (mezo.clientHeight == 27) {
                        mezo.style.borderBottom = '0px';
                        mezo.style.height = '20px';
                    }
                }
            }
        }
        g_maszkJeloles = !g_maszkJeloles;

        updateMenusor();

        objDOM.focus();
    }
}

function changeMaszk(objDOM) {
    var link = 'index.php?oldal=maszkletoltes&amp;rejtvenyID=' + g_rejtvenyID;
    var link2 = link + '&amp;stilus=1';

    var tmp = '<form>';
    tmp += '<fieldset><b>1. A rejtvényről készült maszkok:</b>';
    tmp +=
        '<br>- <a href="' +
        link +
        '" onclick="window.open(\'' +
        link +
        '\'); return false;">Egyszerű maszk</a>';

    if (g_rejtveny.hasGrafika()) {
        tmp +=
            '<br>- <a href="' +
            link2 +
            '" onclick="window.open(\'' +
            link2 +
            '\'); return false;">Aktuális maszk minta</a>';
    }

    tmp += '</fieldset>';
    tmp +=
        '<fieldset><b>2. Egyedi maszk feltöltésének helye:</b><br><input id="maszkFajl" type="file" name="maszkFajl" required="required" accept="image/jpeg" /></fieldset>';
    tmp += '<fieldset>';
    tmp +=
        '<input type="button" onclick="javascript:feltoltesMaszk();" value="Feltöltés">';
    tmp +=
        '<input type="reset" onclick="javascript:hidePopup();" value="Mégse">';

    if (g_rejtveny.hasGrafika()) {
        tmp +=
            '<input type="button" onclick="javascript:torlesMaszk();" value="Törlés">';
    }

    tmp += '</fieldset>';
    tmp += '</form>';

    showPopup(tmp);

    objDOM.focus();
}

function torlesMaszk() {
    g_rejtveny.delGrafika();

    g_elozmeny.mentes('Maszk törlése');
    updateElozmenySav();

    updateMenusor();

    hidePopup();
}

function feltoltesMaszk() {
    var file = document.getElementById('maszkFajl').files[0];

    showPopup(
        '<fieldset><progress id="progressBar" value="0" max="100"></progress><div id="percentageCalc">0%</div></fieldset>',
    );

    var http = new XMLHttpRequest();

    http.upload.addEventListener('progress', progressFgv, false);

    http.open('POST', 'index.php?oldal=maszkmentes', true);

    http.setRequestHeader('Content-type', 'multipart/form-data');
    http.setRequestHeader('X-REJTVENY-ID', g_rejtvenyID);
    http.setRequestHeader('Connection', 'close');

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            var dom = str2xmlDOM(http.responseText);

            g_rejtveny.setGrafika(dom.getElementsByTagName('grafika').item(0));

            g_elozmeny.mentes('Maszk feltöltése');
            updateElozmenySav();

            updateMenusor();

            hidePopup();
        }
    };
    http.send(file);
}

function progressFgv(e) {
    var progressBar = document.getElementById('progressBar');

    var percentageDiv = document.getElementById('percentageCalc');

    if (e.lengthComputable) {
        progressBar.max = e.total;
        progressBar.value = e.loaded;
        percentageDiv.innerHTML = Math.round((e.loaded / e.total) * 100) + '%';
    }
}

function changeSzinsema(objDOM) {
    var tmp = '<form>';
    tmp += '<fieldset id="szinsema">Töltés...</fieldset>';
    tmp += '<fieldset>';
    tmp +=
        '<input type="button" onclick="javascript:setSzinsema();" value="OK">';
    tmp +=
        '<input type="reset" onclick="javascript:hidePopup();" value="Mégse">';
    tmp += '</fieldset>';
    tmp += '</form>';

    showPopup(tmp);

    var http = new XMLHttpRequest();

    http.open('GET', 'index.php?oldal=szinsemak', true);

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            var xmlDOM = str2xmlDOM(http.responseText);

            var element = xmlDOM.getElementsByTagName('minta');
            var i;

            var szinsemak = [];

            for (i = 0; i < element.length; i++) {
                szinsemak[i] = [
                    element[i].getAttribute('keretszín'),
                    element[i].getAttribute('háttérszín'),
                    element[i].getAttribute('betűszín'),
                ];
            }

            var szinek = '';

            var i = 0;
            for (i = 0; i < szinsemak.length; i++) {
                var checked = '';
                if (
                    g_rejtveny.getKeretSzin() == szinsemak[i][0].toUpperCase()
                ) {
                    checked = ' checked="checked"';
                }

                szinek +=
                    '<div class="szinminta"><div class="kulsominta" style="background-color:' +
                    szinsemak[i][0] +
                    ';"><span class="belsominta" style="background-color:' +
                    szinsemak[i][1] +
                    ';">A</span></div><br><input type="radio" name="szinsema" value="' +
                    szinsemak[i].join('-').toUpperCase() +
                    '"' +
                    checked +
                    '></div>';
            }

            document.getElementById('szinsema').innerHTML = szinek;

            updatePopup();
        }
    };

    http.send();

    objDOM.focus();
}

function setSzinsema() {
    var semak = document.getElementsByName('szinsema');

    var ertek = '';

    var i = 0;
    for (i = 0; i < semak.length; i++) {
        if (semak[i].checked) {
            ertek = semak[i].value;
        }
    }

    var szinek = ertek.split('-');

    g_rejtveny.setSzinek(szinek[0], szinek[1], szinek[2]);

    initSzinSema();

    updateMenusor();

    g_elozmeny.mentes('Színséma kiválasztása');
    updateElozmenySav();

    hidePopup();
}

function ugrasMezore(mezoID, vizszintesKijeloles) {
    var mezo = document.getElementById('mezo-' + mezoID);
    g_mezoIrany = vizszintesKijeloles;

    switchObj(mezo);

    mezo.focus();
}

function setKifejezes(kifejezes) {
    var tomb = getTeljesTomb(g_aktualisTomb);
    var aktObj = g_aktualisObj;

    if (tomb.length == kifejezes.length) {
        var mezo;
        var i;

        for (i = 0; i < tomb.length; i++) {
            mezo = document.getElementById('mezo-' + tomb[i]);
            mezo.innerHTML = kifejezes[i];

            g_rejtveny.getMezo(tomb[i]).setErtek(kifejezes[i]);

            switchObj(mezo, true);
        }
    }

    switchObj(aktObj);

    g_elozmeny.mentes('Kifejezés beírása');
    updateElozmenySav();

    aktObj.focus();
}

function szinkronizal(objDOM, kellClass) {
    var lefuthat = false;
    var aktiv = '';

    if (g_rejtveny.countHalozat() > 1) {
        aktiv = '';
        if (g_szinkronSzerkesztes) {
            aktiv = ' class="aktiv"';
        }
        lefuthat = true;
    } else {
        aktiv = ' class="inaktiv"';
    }

    if (kellClass) {
        return aktiv;
    }

    if (lefuthat) {
        g_szinkronSzerkesztes = !g_szinkronSzerkesztes;

        updateMenusor();

        var aktivszinkronmezo = document.getElementById('aktivszinkronmezo');

        if (g_szinkronSzerkesztes) {
            var szikronMezoID = g_rejtveny
                .getNextMezo(objDOM2ID(objDOM))
                .getMezoID();
            var szinkronMezoDOM = document.getElementById(szikronMezoID);

            aktivszinkronmezo.style.left = szinkronMezoDOM.offsetLeft + 'px';
            aktivszinkronmezo.style.top = szinkronMezoDOM.offsetTop + 'px';
            aktivszinkronmezo.style.display = 'block';
        } else {
            aktivszinkronmezo.style.display = 'none';
        }

        objDOM.focus();
    }
}

function updateMeghatarozasok() {
    if (g_epitoMod) {
        var i;

        var oszlop = {};
        var tomb = g_rejtveny.getMeghatarozasok();

        for (i = 0; i < tomb.length; i++) {
            oszlop = document.getElementById('oszlop-' + i);

            oszlop.innerHTML = tomb[i];

            oszlop.onmousedown = onMouseDownDrag;
            oszlop.onmouseup = onMouseUpDrag;
            oszlop.ondblclick = onDblClickDrag;
        }
    }
}

function updateMeghatarozasSav() {
    if (g_epitoMod) {
        var sav = document.getElementById('rejtveny_meghatarozassav');

        if (g_meghatarozasMenu != '') {
            sav.style.display = 'block';
            sav.innerHTML = g_meghatarozasMenu;
        }

        var textarea = document.getElementById('textareaMeghatarozas');
        if (textarea != undefined) {
            textarea.onkeyup = onKeyUpMeghatarozas;
            textarea.onkeypress = onKeyPressTextArea;
        }

        getMeghatarozas();
    }
}

function updateMegoldasSav() {
    var sav = document.getElementById('rejtveny_megoldassav');

    if (g_megoldasMenu != '') {
        sav.style.display = 'block';
        sav.innerHTML = g_megoldasMenu;
    }

    var textarea = document.getElementById('textareaMegoldas');
    if (textarea != undefined) {
        textarea.onkeyup = onKeyUpMegoldas;
        textarea.onkeypress = onKeyPressTextArea;
    }
}

function updateMenusor() {
    document.getElementById('rejtveny_menusor').innerHTML = g_foMenu;
}

function updateElozmenySav() {
    document.getElementById('rejtveny_elozmenysav').innerHTML = g_elozmeny;
}

function elozmenyVisszavonas() {
    g_elozmeny.visszavonas();
    updateElozmenySav();

    updateMenusor();
}

function elozmenyIsmet() {
    g_elozmeny.ismet();
    updateElozmenySav();

    updateMenusor();
}

function elozmenyUgras(csomagID) {
    g_elozmeny.ugras(csomagID);
    updateElozmenySav();

    updateMenusor();

    initSzinSema();
}

function showEllenorzes() {
    showPopup('<fieldset>Ellenőrzés...</fieldset>');

    var ellenorzes = true;

    var hibak = '';

    if (g_epitoMod) {
        if (g_rejtveny.checkKifejezesHibak()) {
            ellenorzes = false;
            hibak += '<br>- hibás kifejezés, meghatározás párok';
        }

        if (g_rejtveny.checkMeghatarozasHibak()) {
            ellenorzes = false;
            hibak += '<br>- hiányzó meghatározások';
        }
    } else {
        var http = new XMLHttpRequest();

        http.open('POST', 'index.php?oldal=ellenorzes', false);

        http.setRequestHeader('Content-type', 'text/xml;charset=UTF-8');
        http.setRequestHeader('X-REJTVENY-ID', g_rejtvenyID);
        http.setRequestHeader('Connection', 'close');

        http.send(g_rejtveny.toXML());

        if (http.responseText === '404') {
            ellenorzes = false;
            hibak += '<br>- nem teljes a rejtvény';
        }
    }

    if (ellenorzes) {
        hidePopup();

        g_ellenorizve = ellenorzes;

        updateMenusor();
    } else {
        var tmp = '<form>';
        tmp += '<fieldset><b>Az ellenőrzés során felismert hibák:</b>';
        tmp += hibak;
        tmp += '</fieldset>';

        tmp += '<fieldset>';
        if (g_epitoMod) {
            tmp +=
                '<input type="button" onclick="javascript:veglegesEllenorzes();" value="Véglegesítés">';
        } else {
            tmp +=
                '<input type="button" onclick="javascript:hidePopup();" value="OK">';
        }

        tmp +=
            '<input type="reset" onclick="javascript:hidePopup();" value="Mégse">';
        tmp += '</fieldset>';
        tmp += '</form>';

        showPopup(tmp);
    }
}

function unEllenorzes() {
    g_ellenorizve = false;

    updateMenusor();
}

function veglegesEllenorzes() {
    g_ellenorizve = true;

    hidePopup();

    updateMenusor();
}

function mentes() {
    showPopup('<fieldset>Mentés...</fieldset>');

    var ellenorizve = 0;
    if (g_ellenorizve) {
        ellenorizve = 1;
    }

    var http = new XMLHttpRequest();

    http.open('POST', 'index.php?oldal=mentes', true);

    http.setRequestHeader('Content-type', 'text/xml;charset=UTF-8');
    if (g_epitoMod) {
        http.setRequestHeader('X-REJTVENY-ID', g_rejtvenyID);
    } else {
        http.setRequestHeader('X-FEJTES-ID', g_fejtesID);
    }

    http.setRequestHeader('X-ELLENORIZVE', ellenorizve);
    http.setRequestHeader('Connection', 'close');

    http.onreadystatechange = function () {
        if (http.readyState == 4 && http.status == 200) {
            if (http.responseText === '200') {
                hidePopup();

                document.getElementById('zarRejtveny').style.display = 'none';
                document.getElementById('zarFixMenu').style.display = 'none';

                g_mentve = true;

                updateMenusor();
            }
        }
    };
    http.send(g_rejtveny.toXML());
}

function mentesMegse() {
    hidePopup();

    document.getElementById('zarRejtveny').style.display = 'none';
    document.getElementById('zarFixMenu').style.display = 'none';
}

var g_dragOK = false;
var x, y, dx, dy;
var g_dragObj = {};

function onMouseDownDrag(e) {
    var event = e || window.event;
    var node = event.srcElement || event.target;

    if (
        node.getAttribute('class') != undefined &&
        node.getAttribute('class').search('oszlop2') !== -1
    ) {
        g_dragObj = node;

        g_dragObj.style.position = 'relative';
        g_dragObj.style.boxShadow = '0px 0px 3px 0px rgb(0, 0, 0)';

        g_dragOK = true;

        dx = parseInt(g_dragObj.style.left + 0);
        dy = parseInt(g_dragObj.style.top + 0);
        x = e.pageX;
        y = e.pageY;
        document.onmousemove = onMouseMoveDrag;
        return false;
    }
}

function onMouseUpDrag(e) {
    g_dragOK = false;
    document.onmousemove = null;
}

function onDblClickDrag(e) {
    var event = e || window.event;
    var node = event.srcElement || event.target;

    g_dragObj = node;

    g_dragObj.style.position = 'static';
    g_dragObj.style.left = '0px';
    g_dragObj.style.top = '0px';
    g_dragObj.style.boxShadow = '';
}

function onMouseMoveDrag(e) {
    var event = e || window.event;

    if (g_dragOK) {
        g_dragObj.style.left = dx + event.pageX - x + 'px';
        g_dragObj.style.top = dy + event.pageY - y + 'px';
        return false;
    }
}
